@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Thin.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Light.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Black.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Bold.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Regular.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-ExtraLight.woff2') format('woff2'),
  url('../fonts/TTWellingtons-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Black.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Black.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-DemiBold.woff2') format('woff2'),
  url('../fonts/TTWellingtons-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-Medium.woff2') format('woff2'),
  url('../fonts/TTWellingtons-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Wellingtons';
  src: url('../fonts/TTWellingtons-ExtraBold.woff2') format('woff2'),
  url('../fonts/TTWellingtons-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Regular.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Bold.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Heavy.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Light.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Medium.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Ultralight.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Ultralight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Display';
  src: url('../fonts/SFUIDisplay-Thin.woff2') format('woff2'),
  url('../fonts/SFUIDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Bold.woff2') format('woff2'),
  url('../fonts/SFUIText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Light.woff2') format('woff2'),
  url('../fonts/SFUIText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Heavy.woff2') format('woff2'),
  url('../fonts/SFUIText-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Regular.woff2') format('woff2'),
  url('../fonts/SFUIText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Medium.woff2') format('woff2'),
  url('../fonts/SFUIText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('../fonts/SFUIText-Semibold.woff2') format('woff2'),
  url('../fonts/SFUIText-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Object Sans';
  src: url('../fonts/ObjectSans-Regular.woff2') format('woff2'),
  url('../fonts/ObjectSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Object Sans';
  src: url('../fonts/ObjectSans-Heavy.woff2') format('woff2'),
  url('../fonts/ObjectSans-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Black.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Thin.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Ultralight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Light.woff2') format('woff2'),
  url('../fonts/Stem-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem ExtLt';
  src: url('../fonts/Stem-ExtraLight.woff2') format('woff2'),
  url('../fonts/Stem-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Bold.woff2') format('woff2'),
  url('../fonts/Stem-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Regular.woff2') format('woff2'),
  url('../fonts/Stem-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem Med';
  src: url('../fonts/Stem-Medium.woff2') format('woff2'),
  url('../fonts/Stem-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem SemLt';
  src: url('../fonts/Stem-SemiLight.woff2') format('woff2'),
  url('../fonts/Stem-SemiLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Black.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Bold.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../fonts/Stem-Thin.woff2') format('woff2'),
  url('../fonts/Stem-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-DemiBold.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Light.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraBold.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-ExtraLight.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Regular.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Medium.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Firs Neue';
  src: url('../fonts/TTFirsNeue-Thin.woff2') format('woff2'),
  url('../fonts/TTFirsNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neutral Face';
  src: url('../fonts/NeutralFaceRegular.woff2') format('woff2'),
  url('../fonts/NeutralFaceRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neutral Face';
  src: url('../fonts/NeutralFaceBold.woff2') format('woff2'),
  url('../fonts/NeutralFaceBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Black.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Thin.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces ExtBd';
  src: url('../fonts/TTInterfaces-ExtraBold.woff2') format('woff2'),
  url('../fonts/TTInterfaces-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TTInterfaces-Bold.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces ExtLt';
  src: url('../fonts/TTInterfaces-ExtraLight.woff2') format('woff2'),
  url('../fonts/TTInterfaces-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TTInterfaces-Black.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
  url('../fonts/SFProDisplay-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Bold.woff2') format('woff2'),
  url('../fonts/SFProText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TTInterfaces-Regular.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TTInterfaces-Light.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces Med';
  src: url('../fonts/TTInterfaces-Medium.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../fonts/TTInterfaces-Thin.woff2') format('woff2'),
  url('../fonts/TTInterfaces-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Black.woff2') format('woff2'),
  url('../fonts/VisueltPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Thin.woff2') format('woff2'),
  url('../fonts/VisueltPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-ExtraLight.woff2') format('woff2'),
  url('../fonts/VisueltPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Light.woff2') format('woff2'),
  url('../fonts/VisueltPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Bold.woff2') format('woff2'),
  url('../fonts/VisueltPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Medium.woff2') format('woff2'),
  url('../fonts/VisueltPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Visuelt Pro';
  src: url('../fonts/VisueltPro-Regular.woff2') format('woff2'),
  url('../fonts/VisueltPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


html{
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }

}

@keyframes showContent {
  to{
    opacity: 1;
    transform: translateY(0px);
  }
}

#pages{
  .page-content{
    display: none;
    &:first-child{
      display: block;
    }
  }
}

body {
  font-family: 'TT Wellingtons';
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #5B6076;
  background: #FFFFFF;
  //overflow-x: hidden;
  @media screen and (max-width: 800px) {
    width: 100%;
    overflow-x: hidden;
    .hide-on-mobile{
      display: none !important;
    }
  }

  .red-button{
    transition: all 300ms linear;
    white-space:nowrap;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    i{
      position: relative;
      display: block;
      color: #fff;
      font-style: normal;
    }
    &:before{
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0;
      transition: all 500ms linear;
      background: linear-gradient(92.45deg, #FF9400 0%, #FF6600 100%);
    }
    &:hover{
      &:before{
        opacity: 1;
      }
    }
  }
  .glass-button{
    &:hover{
      background: rgba(255, 255, 255, 0.21) !important;
    }
  }
  .gray-button{
    &:hover{
      background: #E3E6E8 !important;
    }
  }
  .white-button{
    &:hover{
      background: #CCCCCC !important;
    }
  }


  h2{
    margin: 0;
    padding: 0;
    margin-bottom: 56px;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    letter-spacing: -0.06em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media screen and (max-width: 800px) {
      font-weight: 500;
      font-size: 32px;
      line-height: 100%;
    }

  }
  h4{
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.04em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media screen and (max-width: 800px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
    }
  }


  #main-menu{
    position: fixed;
    display: block;
    z-index: 100;
    height: 100%;
    width: 430px;
    left: -440px;
    top: 0;
    transition: all 400ms ease-out;
    .menu-mobile-logo{
      display: none;
    }
    &.active{
      left: 0;
    }
    @media screen and (max-width: 800px) {
      width: 100vw;
      max-width: 440px;
      .menu-mobile-logo{
        display: block;
      }
      .menu-desktop-logo{
        display: none;
      }

    }
    background: linear-gradient(0deg, #0C1425, #0C1425), linear-gradient(0deg, #1F2B42, #1F2B42), #FFFFFF;
    #main-menu-header{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      gap: 24px;
      width: 100%;
      height: 70px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      #close-menu{
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 16px;
        background: rgba(243, 243, 243, 0.15) url(../img/close.svg) no-repeat 50% 50%;
        cursor: pointer;
        &:hover{
          background-color: rgba(255,255,255,.21);
        }
        @media screen and (max-width: 800px) {
          position: absolute;
          top: 11px;
          right: 16px;
          width: 48px;
          height: 48px;
        }

      }

    }
    #main-menu-mobile-items{
      display: none;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      padding-top: 20px;
      overflow-y: scroll;
      height: calc(100vh - 240px);
      .open-menu-list-items{
        display: none;
        span{
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #B8C0CF;
          opacity: 0.8;
          position: relative;
          display: block;
          margin-bottom: 19px;
        }
        a{
          position: relative;
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #FFFFFF;
          margin-bottom: 14px;
        }
      }
      a{
        position: relative;
        display: block;
        font-weight: 400;
        font-size: 24px;
        line-height: 44px;
        letter-spacing: -0.04em;
        color: #B8C0CF;
        text-decoration: none;
        svg{
          position: relative;
          display: inline-block;
          vertical-align: middle;
          top: -2px;
        }
        &.active{
          svg{
            transform: rotate(180deg);
          }
        }
        &:hover{
          background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            background: #fff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

        }
      }
    }
    #mobile-menu-footer{
      position: absolute;
      display: none;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background: #0F192E;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      padding-top: 24px;
      box-sizing: border-box;
      a{
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #B8C0CF;
        img{
          position: relative;
          display: inline-block;
          vertical-align: middle;
          top:-2px;
          width: 16px;
          margin-right: 4px;
        }
      }
      .menu-auth{
        position: relative;
        display: block;
        width: 100%;
        height: 56px;
        background: #FFFFFF;
        border-radius: 16px;
        font-weight: 600;
        font-size: 16px;
        line-height: 56px;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #0C1426;
        text-decoration: none;
        margin-top: 2px;
      }
    }
    @media screen and (max-width: 800px) {
      #main-menu-items{
        display: none !important;
      }
      #main-menu-mobile-items{
        display: flex;
      }
      #mobile-menu-footer{
        display: flex;
      }
    }

    #main-menu-items{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20px;
      margin-top: 35px;
      a{
        position: relative;
        display: block;
        font-weight: 400;
        font-size: 32px;
        line-height: 61px;
        letter-spacing: -0.04em;
        color: #B8C0CF;
        text-decoration: none;
        &:hover{
          background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }

  header{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    box-sizing: border-box;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    transition: background-color 200ms linear;
    transform: translateY(-70px);
    animation: showContent 1s forwards cubic-bezier(.17,.67,.42,.96);
    @media screen and (max-width: 800px) {
      display: block;
      padding: 0 20px;
    }

    &.darkbg{
      background: #0F192E !important;
    }
    &.whitebg{
      background: #fff;
      .header-right{
        a{
          color: #5B6076;
          &.auth-btn{
            background: #0F192E;
            color: #fff;
            &:hover{
              span{
                color:#fff !important;
                background: none;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
                background-clip: unset;
                text-fill-color: none;
              }
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            }
            span{
              background: #fff;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }
      }
      .header-left{
        #burger{
          background: rgba(226, 228, 233, 0.5);
          svg path{
            stroke: #121630;
          }
        }
        .header-selects{
          .header-select{
            color: #5B6076;
            svg path{
              fill: #5B6076;
            }
          }
        }
        #header-logo{
          &:after{
            opacity: 1;
          }
        }
      }
    }

    &.whitebgalways{
      background: linear-gradient(180deg, #fff, rgba(255, 255, 255, 0));
      transition: all 300ms linear;
      &.scrolled{
        background: #fff;
      }
      .header-right{
        a{
          color: #5B6076;
          &.auth-btn{
            background: #0F192E;
            color: #fff;
            &:hover{
              span{
                color:#fff !important;
                background: none;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
                background-clip: unset;
                text-fill-color: none;
              }
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            }
            span{
              background: #fff;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }
      }
      .header-left{
        #burger{
          background: rgba(226, 228, 233, 0.5);
          svg path{
            stroke: #121630;
          }
        }
        .header-selects{
          .header-select{
            &:nth-child(1){
              border-right: 1px solid rgba(0,0,0,.08);
            }
            color: #5B6076;
            svg path{
              fill: #5B6076;
            }
          }
        }
        #header-logo{
          &:after{
            opacity: 1;
          }
        }
      }

      &.darkbg{
        .header-left{
          #header-logo{
            &:after{
              opacity: 0;
            }
          }
          .header-selects{
            .header-select{
              color: #fff;
              svg path{
                fill: #fff;
              }
            }
          }
        }
        .header-right{
          a{
            color: #b8c0cf;
          }
          a.auth-btn{
            background: #fff;
            &:hover{
              span{
                color:#fff !important;
                background: none;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
                background-clip: unset;
                text-fill-color: none;
              }
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            }
            span{
              -webkit-text-fill-color: transparent;
              text-fill-color: transparent;
              background: linear-gradient(95.33deg,#121630,#596077);
              -webkit-background-clip: text;
              background-clip: text;
            }
          }
        }

      }
    }

    .header-left{
      position: relative;
      display: flex;
      align-items: center;
      gap: 24px;
      font-size: 0;
      line-height: 0;
      #header-logo{
        position: relative;
        display: block;
        width: 114px;
        height: 23px;
        background: url(../img/logo_rastr.webp) no-repeat;
        background-size: 100% 100%;
        &:after{
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background: url(../img/logo_dark_rastr.webp) no-repeat;
          background-size: 100% 100%;
          opacity: 0;
          transition: all 200ms linear;
        }
      }

      @media screen and (max-width: 800px) {
        height: 70px;
      }
      #burger{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 16px;
        background: rgba(243, 243, 243, 0.15);
        cursor: pointer;
        transition: all 200ms linear;
        &:hover{
          background-color: rgba(255,255,255,.21);
        }

        @media screen and (max-width: 800px) {
          position: absolute;
          right: 0;
          left: auto;
          top: 11px;
          width: 48px;
          height: 48px;
        }
      }
      .header-selects{
        position: relative;
        display: flex;
        justify-content: center;
        width: 246px;
        height: 40px;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.08);
        box-sizing: border-box;
        overflow: hidden;
        box-shadow: 0px 8px 104px rgba(0, 0, 0, 0.08);
        @media screen and (max-width: 800px) {
          display: none;
        }

        .header-select{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 50%;
          font-size: 14px;
          line-height: 20px;
          padding-left: 8px;
          font-weight: 600;
          color: #B8C0CF;
          cursor: pointer;
          &:hover{
            background: rgba(255, 255, 255, 0.1);
          }
          &.active{
            background: rgba(255, 255, 255, 0.1);
            svg{
              transform: rotate(180deg);
            }
          }
          svg path{
            transition: all 200ms linear;
          }
          &:first-child{
            border-right: 1px solid rgba(255, 255, 255, 0.08);
          }
        }
      }
    }
    .header-right{
      position: relative;
      display: flex;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 800px) {
        display: flex;
        position: absolute;
        right: 84px;
        text-align: right;
        top: 21px;
        gap: 0;
        flex-direction: column-reverse;
      }
      .header-divider{
        position: relative;
        display: block;
        width: 1px;
        height: 20px;
        background: #FFFFFF;
        opacity: 0.08;
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
      a{
        position: relative;
        display: block;
        color: #B8C0CF;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        transition: all 200ms linear;
        font-feature-settings: 'pnum' on, 'lnum' on;
        @media screen and (max-width: 800px) {
          font-size: 12px;
          line-height: 16px;
        }
        &:hover{
          background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        &.auth-btn{
          padding: 0 20px;
          line-height: 40px;
          border-radius: 16px;
          background: #fff;
          @media screen and (max-width: 800px) {
            display: none;
          }
          &:hover{
            span{
              color:#fff !important;
              background: none;
              -webkit-background-clip: initial;
              -webkit-text-fill-color: initial;
              background-clip: unset;
              text-fill-color: none;
            }
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
          }
          span{
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
  }

  #open-header-menu{
    position: fixed;
    z-index: 100;
    .open-header-menu{
      position: fixed;
      display: none;
      &.active{
        display: flex;
      }
      justify-content: space-between;
      width: 100%;
      left: 0;
      top:70px;
      padding: 32px 20px;
      border-top: rgba(255, 255, 255, 0.12) solid 1px;
      box-sizing: border-box;
      background: #0F192E;
      .open-header-menu-text{
        position: relative;
        display: block;
        width: 23.2%;
        margin-right: 4.7%;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .open-header-menu-items{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 22.1%;
        margin-right: 1.25%;
        a{
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #B8C0CF;
          text-decoration: none;
          &:hover{
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
  }

  footer{
    position: relative;
    display: block;
    background: #0C1425 url(../img/footer.jpg) no-repeat 50% 50%;
    background-size: cover;
    min-height: 500px;
    @media screen and (max-width: 800px) {
      padding-bottom: 24px;
    }
    .container{
      display: flex;
      justify-content: space-between;
      padding: 0 40px;
      @media screen and (max-width: 800px) {
        padding: 0 16px;
        display: block;
      }
      .subscribe{
        white-space: nowrap;
        @media screen and (max-width: 800px) {
          white-space: normal;
        }
      }

      &:first-child{
        height: 176px;
        @media screen and (max-width: 800px) {
          height: auto;
          padding-top: 56px;
        }
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);
        span{
          font-weight: 500;
          font-size: 32px;
          line-height: 140%;
          letter-spacing: -0.04em;
          background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
          }
        }
        input{
          padding: 0 16px;
          width: 298px;
          height: 64px;
          border-radius: 16px;
          border: 1.5px solid rgba(255, 255, 255, 0.16);
          box-sizing: border-box;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #fff;
          background: none;
          outline: none;
          &::-webkit-input-placeholder{
            color: #B8C0CF;
          }
          &:hover{
            border: 1.5px solid rgba(255, 255, 255, 0.92);
          }
          &:focus{
            border: 1.5px solid rgba(255, 255, 255, 0.92);
          }
          @media screen and (max-width: 800px) {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 16px;
            height: 56px;
          }
        }
        button{
          box-sizing: border-box;
          outline: none;
          border: 0;
          width: 181px;
          height: 64px;
          margin-left: 8px;
          //background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.15) 0%, rgba(243, 243, 243, 0.12) 100%);
          //backdrop-filter: blur(95px);
          //-webkit-backdrop-filter: blur(95px);
          background: #3E485B;
          border-radius: 16px;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          cursor: pointer;
          font-family: 'TT Wellingtons';

          @media screen and (max-width: 800px) {
            width: 100%;
            height: 56px;
            margin-left: 0;
            margin-bottom: 50px;
            font-size: 16px;
          }
          &:hover{
            background: rgba(255, 255, 255, 0.21);
            backdrop-filter: blur(95px);
            -webkit-backdrop-filter: blur(95px);
          }
        }
      }
      &:nth-child(2){
        align-items: center;
        margin: 56px 0;
        @media screen and (max-width: 800px) {
          margin: 50px 0;
        }
        .footer-contacts{
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            margin-top: 40px;
          }
          a{
            font-weight: 600;
            font-size: 16px;
            line-height: 125%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #FFFFFF;
            text-decoration: none;
            img{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              margin-right: 4px;
              top:-1px;
            }
          }
          .footer-social{
            position: relative;
            display: flex;
            justify-content: space-between;
            gap: 4px;
            margin-left: 31px;
            @media screen and (max-width: 800px) {
              margin-left: 0px;
            }
            a{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              //background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.15) 0%, rgba(243, 243, 243, 0.12) 100%);
              //backdrop-filter: blur(95px);
              //-webkit-backdrop-filter: blur(95px);
              background: #3E485B;
              border-radius: 16px;
              &:hover{
                background: rgba(255, 255, 255, 0.21);
                backdrop-filter: blur(95px);
                -webkit-backdrop-filter: blur(95px);
              }
            }
          }
        }
      }
      &.mobile-links-block{
        display: none;
        @media screen and (max-width: 800px) {
          display: flex;
          flex-direction: column;
          gap:0;
          .mobile-accordion{
            .mobile-accordion-title{
              font-weight: 500;
              font-size: 24px;
              line-height: 40px;
              letter-spacing: -0.06em;
              background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 8px;
              display: block;
              svg{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                top: -1px;
              }
            }

            .mobile-accordion-items{
              position: relative;
              display: none;
              gap: 14px;
              margin-bottom: 16px;
              flex-direction: column;
              a{
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 0;
                background: none;
                -webkit-background-clip: initial;
                -webkit-text-fill-color: initial;
                background-clip: initial;
                text-fill-color: none;
                text-decoration: none;
                color: #FFFFFF;
              }
              .footer-link-accordion{
                .footer-link-accordion-title{
                  color: #fff;
                  font-size: 16px;
                  font-weight: 500;
                  text-decoration: none;
                  cursor: pointer;
                  svg{
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    top: -1px;
                  }
                }
                .footer-link-accordion-links{
                  position: relative;
                  display: none;
                  margin-top: 6px;
                  flex-direction: column;
                  gap: 6px;
                  padding-left: 16px;
                  a{
                    color: #B8C0CF;
                    font-weight: 500;
                    text-decoration: none;
                    opacity: 0.7;
                    &:hover{
                      background: none;
                      -webkit-background-clip: initial;
                      -webkit-text-fill-color: initial;
                      background-clip: initial;
                      text-fill-color: none;
                      color: #FFFFFF;
                    }
                  }
                }
                &.active{
                  .footer-link-accordion-title{
                    svg{
                      transform: rotate(180deg);
                    }
                  }
                  .footer-link-accordion-links{
                    display: flex;
                    gap: 6px;
                  }
                }

              }
            }
            &.active{
              .mobile-accordion-title{
                svg{
                  transform: rotate(180deg);
                }
              }
              .mobile-accordion-items{
                display: flex;
              }
            }
          }
          a{
            font-weight: 500;
            font-size: 24px;
            line-height: 40px;
            //letter-spacing: -0.06em;
            background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 8px;
            display: block;
            svg{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
            }
          }
        }
      }
      &.links-block{
        margin-bottom: 56px;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        gap: 16px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        @media screen and (max-width: 800px) {
          display: none;
        }

        span{
          position: relative;
          display: block;
          width: 100%;
          color: #636979;
        }
        .footer-link{
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: calc(33.333% - 14px);
          .footer-link-accordion{
            .footer-link-accordion-title{
              color: #B8C0CF;
              font-weight: 500;
              text-decoration: none;
              cursor: pointer;
              &:hover{
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                svg path{
                  fill: #FF9400
                }
              }
              svg{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                top: -1px;
              }
            }
            .footer-link-accordion-links{
              position: relative;
              display: none;
              margin-top: 6px;
              flex-direction: column;
              gap: 6px;
              a{
                color: #B8C0CF;
                font-weight: 500;
                text-decoration: none;
                opacity: 0.7;
                &:hover{
                  background: none;
                  -webkit-background-clip: initial;
                  -webkit-text-fill-color: initial;
                  background-clip: initial;
                  text-fill-color: none;
                  color: #FFFFFF;
                }
              }
            }
            &.active{
              .footer-link-accordion-title{
                svg{
                  transform: rotate(180deg);
                }
              }
              .footer-link-accordion-links{
                display: flex;
                gap: 6px;
              }
            }

          }
          a{
            color: #B8C0CF;
            font-weight: 500;
            text-decoration: none;
            &:hover{
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              svg path{
                fill: #FF9400
              }
            }
            svg{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
            }
          }
        }
      }
      &:last-child{
        align-items: center;
        justify-content: flex-start;
        gap: 32px;
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        height: 79px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        @media screen and (max-width: 800px) {
          height: auto;
          border: none;
          flex-direction: column;
          margin-top: 40px;
        }
        a{
          position: relative;
          display: block;
          color: #636979;
          text-decoration: none;
          @media screen and (max-width: 800px) {
            margin-top: 12px
          }
          &:hover{
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            svg path{
              fill: #FF9400
            }
          }
          &.created-by{
            position: absolute;
            display: block;
            right: 40px;
            @media screen and (max-width: 800px) {
              position: relative;
              left: 0;
              right: auto;
              margin-top: 24px;
            }
            svg{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: -1px;
            }
          }
        }
      }
    }
  }

  .overflow-section{
    overflow-x: hidden;
  }

  section{
    position: relative;
    display: block;
    overflow-x: hidden;
    &.home-top{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background: #0F192E url(../img/home/bg.png) no-repeat 50% 50%;
      background-size: cover;
      #home-top-back{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .cube-scroll{
          position: relative;
          display: block;
        }
        .cube1{
          position: absolute;
          display: block;
          width: 47.42%;
          top: -1.68%;
          right: 83.28%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(160px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube2{
          position: absolute;
          display: block;
          width: 69.06%;
          top: 20.5%;
          left: 15.47%;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(170px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube3{
          position: absolute;
          display: block;
          width: 94.45%;
          top: -8.42%;
          left: 35%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(100px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .duga1{
          position: absolute;
          display: block;
          height: 106%;
          right: 72.73%;
          top: 33.84%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            height: 100%;
            opacity: 0;
            transform: translateY(0px);
            animation: showContent 1s forwards cubic-bezier(.17,.67,.42,.96);
            animation-delay: 0.5s;
          }
          &.duga12{
            right: 74.76%;
            top: 28.23%;
            transition: none;
          }
          &.duga13{
            right: 75.46%;
            top: 23.17%;
          }
        }
        .duga2{
          position: absolute;
          display: block;
          height: 89.46%;
          left: 87.5%;
          top: -8.28%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            height: 100%;
            opacity: 0;
            transform: translateY(0px);
            animation: showContent 1s forwards cubic-bezier(.17,.67,.42,.96);
            animation-delay: 0.5s;
          }
          &.duga22{
            left: 86.95%;
            top: -4.07%;
            transition: none;
          }
          &.duga23{
            left: 85.31%;
            top: 0.56%;
          }
        }
      }
      @media screen and (max-width: 800px) {
        #home-top-back{
          display: none;
        }
        background: #0F192E url(../img/mobile_top_bg.png) no-repeat 50% 50%;
        background-size: cover;
      }
      .home-top-content{
        position: relative;
        display: block;
        text-align: center;
        color: #B8C0CF;
        opacity: 0;
        transform: translateY(100px);
        animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
        animation-delay: 0.5s;
        @media screen and (max-width: 800px) {
          padding: 0 16px;
          box-sizing: border-box;
          width: 100%;
          text-align: left;
          position: absolute;
          bottom: 32px;
        }

        b{
          position: relative;
          display: block;
          background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          margin-bottom: 24px;
          padding: 10px;
          @media screen and (max-width: 800px) {
            font-size: 54px;
            line-height: 54px;
            text-align: left;
            padding: 0;
          }
        }
        .home-top-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 40px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100%;
            }
            &:nth-child(2){
              width: 220px;
              background: #3E485B;
              //backdrop-filter: blur(95px);
              //-webkit-backdrop-filter: blur(95px);
              &:hover{
                background: rgba(255, 255, 255, 0.21);
              }
              @media screen and (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &#bookmarks{
      .container{
        margin:80px 0 60px;
        display: flex;
        justify-content: center;
        gap: 32px;
        &.no-margin{
          margin-bottom: 0;
        }
        @media screen and (max-width: 800px) {
          display: block;
          overflow-x: scroll;
          white-space: nowrap;
          margin: 30px 0;
          &::-webkit-scrollbar{
            display: none;
          }
          a{
            display: inline-block !important;
            margin: 0 16px 40px !important;
            font-size: 16px;
            span{
              padding-bottom: 8px !important;
            }
          }
        }
        a{
          position: relative;
          display: block;
          margin-bottom: 32px;
          text-decoration: none;
          opacity: 0.5;
          cursor: pointer;
          span{
            position: relative;
            display: block;
            text-align: center;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            padding-bottom: 16px;
            @media screen and (max-width: 800px) {
              font-size: 16px;
            }
          }
          &:after{
            position: absolute;
            display: none;
            width: 100%;
            bottom: 0;
            height: 3px;
            border-radius: 3px;
            content: "";
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%) ;
          }
          &.active{
            opacity: 1;
            &:after{
              display: block;
            }
          }
          &:hover{
            opacity: 1;
          }

        }
      }
    }

    &.solution-top{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background: #0F192E url(../img/home/bg.png) no-repeat 50% 50%;
      background-size: cover;
      #solution-top-back{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .cube-scroll{
          position: relative;
          display: block;
        }
        &.solution-top-back1{
          .cube1{
            position: absolute;
            display: block;
            width: 81.9%;
            top: -18%;
            left: -26.95%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 105.7%;
            top: 21.58%;
            left: 35%;
            transition: transform 800ms ease-out;
          }
        }
        &.solution-top-back2{
          .cube1{
            position: absolute;
            display: block;
            left: -7.95%;
            top: 2%;
            width: 81.9%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 137.18%;
            top: -48.42%;
            left: 18%;
            transition: transform 800ms ease-out;
          }
        }
        &.solution-top-back3{
          .cube1{
            display: block;
            left: -27.95%;
            position: absolute;
            top: -8%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 32%;
            position: absolute;
            top: -26.42%;
            transition: transform .8s ease-out;
            width: 105.7%;
          }
        }
        &.solution-top-back4{
          .cube1{
            display: block;
            left: -34.95%;
            position: absolute;
            top: -38%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 32%;
            position: absolute;
            top: 24.58%;
            transition: transform .8s ease-out;
            width: 105.7%;
          }
        }
        &.solution-top-back5{
          .cube1{
            display: block;
            left: -47.95%;
            position: absolute;
            top: 2%;
            width: 100.85%;
          }
          .cube2{
            display: block;
            left: -10.95%;
            position: absolute;
            top: 14%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 45%;
            position: absolute;
            top: -6.42%;
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }
        &.solution-top-back6{
          .cube1{
            display: block;
            left: -71.95%;
            position: absolute;
            top: -7%;
            width: 113.7%;
          }
          .cube2{
            display: block;
            left: -24.95%;
            position: absolute;
            top: calc(50% - 106vh);
            width: 136.9%;
          }
          .cube3{
            display: block;
            left: 57%;
            position: absolute;
            top: 0.58%;
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }
        &.solution-top-back7{
          .cube1{
            display: block;
            left: -32.95%;
            position: absolute;
            top: 0%;
            width: 67.73%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 113.7%;
            top: 17.58%;
            left: 35%;
            transition: transform 800ms ease-out;
          }
        }
        &.solution-top-back8{
          .cube1{
            display: block;
            left: -30.95%;
            position: absolute;
            top: 27%;
            width: 71.7%;
          }
          .cube2{
            display: block;
            left: 14.05%;
            position: absolute;
            top: calc(50% - 76vh);
            width: 86.9%;
          }
          .cube3{
            display: block;
            left: 47%;
            position: absolute;
            top: calc(50% - 76vh);
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }

        .cube1{
          position: absolute;
          display: block;
          width: 47.42%;
          top: -1.68%;
          right: 83.28%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(160px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube2{
          position: absolute;
          display: block;
          width: 69.06%;
          top: 20.5%;
          left: 15.47%;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(170px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube3{
          position: absolute;
          display: block;
          width: 94.45%;
          top: -8.42%;
          left: 35%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(100px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
      }

      #solution-top-back{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      @media screen and (max-width: 800px) {
        #solution-top-back{
          display: none;
        }
        background: #0F192E url(../img/mobile_top_bg.png) no-repeat 50% 50%;
        background-size: cover;
      }
      .solution-top-content{
        position: relative;
        display: block;
        text-align: center;
        color: #B8C0CF;
        opacity: 0;
        transform: translateY(100px);
        animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
        animation-delay: 0.5s;
        @media screen and (max-width: 800px) {
          padding: 0 16px;
          box-sizing: border-box;
          width: 100%;
          text-align: left;
          position: relative;
          padding-top: 45px;

          br{
            content: " ";
            display: inline-block;
            width: 4px;
          }
        }

        #solution-tumbler{
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 295px;
          height: 48px;
          box-sizing: border-box;
          margin: 0 auto 56px;
          background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.1) 0%, rgba(243, 243, 243, 0.08) 100%);
          backdrop-filter: blur(95px);
          -webkit-backdrop-filter: blur(95px);
          border-radius: 16px;
          padding: 6px;
          @media screen and (max-width: 800px) {
            width: 270px;
            height: 45px;
            margin: 0;
            margin-bottom: 34px;
          }
          .solution-tumbler{
            position: relative;
            display: block;
            width: 136px;
            &:nth-child(2){
              width: 145px;
            }
            height: 36px;
            //opacity: 0.4;
            border-radius: 12px;
            text-align: center;
            line-height: 36px;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: -0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #B8C0CF;
            cursor: pointer;
            &:hover{
              .tooltip{
                visibility: visible;
              }
            }
            .tooltip{
              position: absolute;
              display: block;
              visibility: hidden;
              right: 19px;
              top: 48px;
              width: 315px;
              height: 72px;
              background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.1) 0%, rgba(243, 243, 243, 0.08) 100%);
              box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.16);
              backdrop-filter: blur(47.5px);
              border-radius: 16px 0px 16px 16px;
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              text-align: left;
              color: #B8C0CF;
              padding: 16px;
              box-sizing: border-box;
              &.white-tooltip{
                background: linear-gradient(94.71deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
                box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.07);
                backdrop-filter: blur(47.5px);
                color: #5B6076;
              }
            }
            &:nth-child(2){
              .tooltip{
                right: -288px;
                border-radius: 0px 16px 16px 16px;
              }
            }
            @media screen and (max-width: 800px) {
              .tooltip{
                display: none;
              }
              width: 129px;
              height: 33px;
              line-height: 33px;
              font-size: 12px;
            }
            img{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: 0;
              margin-left: 3px;
            }
            &.active{
              background: #4E5369;
              color: #fff;
            }
          }
        }

        b{
          position: relative;
          display: block;
          background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 500;
          font-feature-settings: 'pnum' on, 'lnum' on;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          margin-bottom: 24px;
          padding: 10px;
          span{
            display: none;
          }
          @media screen and (max-width: 800px) {
            padding: 0;
            font-size: 52px;
            line-height: 56px;
            text-align: left;
            span{
              display: inline-block;
            }
            &.mobile-wordbreak{
              word-break: break-all;
              margin-right: -10px;
            }
            br{
              content: " ";
              display: inline-block;
              width: 14px;
            }
          }
        }
        .solution-top-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 40px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100% !important;
            }
            &:nth-child(2){
              width: 212px;
              color: #fff;
              //background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.16) 0%, rgba(243, 243, 243, 0.128) 100%);
              //backdrop-filter: blur(95px);
              //-webkit-backdrop-filter: blur(95px);
              background: #3E485B;
              @media screen and (max-width: 800px) {
                width: 100% !important;
              }
            }
          }
        }
      }
    }

    &.product-top{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background: #0F192E url(../img/prod_bg1.png) no-repeat 50% 50%;
      background-size: cover;
      #product-top-back{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .cube-scroll{
          position: relative;
          display: block;
        }
        &.product-top-back1{
          .cube1{
            position: absolute;
            display: block;
            width: 81.9%;
            top: -18%;
            left: -26.95%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 105.7%;
            top: 21.58%;
            left: 35%;
            transition: transform 800ms ease-out;
          }
        }
        &.product-top-back2{
          .cube1{
            position: absolute;
            display: block;
            left: -7.95%;
            top: 2%;
            width: 81.9%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 137.18%;
            top: -48.42%;
            left: 18%;
            transition: transform 800ms ease-out;
          }
        }
        &.product-top-back3{
          .cube1{
            display: block;
            left: -27.95%;
            position: absolute;
            top: -8%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 32%;
            position: absolute;
            top: -26.42%;
            transition: transform .8s ease-out;
            width: 105.7%;
          }
        }
        &.product-top-back4{
          .cube1{
            display: block;
            left: -34.95%;
            position: absolute;
            top: -38%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 32%;
            position: absolute;
            top: 24.58%;
            transition: transform .8s ease-out;
            width: 105.7%;
          }
        }
        &.product-top-back5{
          .cube1{
            display: block;
            left: -47.95%;
            position: absolute;
            top: 2%;
            width: 100.85%;
          }
          .cube2{
            display: block;
            left: -10.95%;
            position: absolute;
            top: 14%;
            width: 81.9%;
          }
          .cube3{
            display: block;
            left: 45%;
            position: absolute;
            top: -6.42%;
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }
        &.product-top-back6{
          .cube1{
            display: block;
            left: -71.95%;
            position: absolute;
            top: -7%;
            width: 113.7%;
          }
          .cube2{
            display: block;
            left: -24.95%;
            position: absolute;
            top: calc(50% - 106vh);
            width: 136.9%;
          }
          .cube3{
            display: block;
            left: 57%;
            position: absolute;
            top: 0.58%;
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }
        &.product-top-back7{
          .cube1{
            display: block;
            left: -32.95%;
            position: absolute;
            top: 0%;
            width: 67.73%;
          }
          .cube3{
            position: absolute;
            display: block;
            width: 113.7%;
            top: 17.58%;
            left: 35%;
            transition: transform 800ms ease-out;
          }
        }
        &.product-top-back8{
          .cube1{
            display: block;
            left: -30.95%;
            position: absolute;
            top: 27%;
            width: 71.7%;
          }
          .cube2{
            display: block;
            left: 14.05%;
            position: absolute;
            top: calc(50% - 76vh);
            width: 86.9%;
          }
          .cube3{
            display: block;
            left: 47%;
            position: absolute;
            top: calc(50% - 76vh);
            transition: transform .8s ease-out;
            width: 113.7%;
          }
        }

        .cube1{
          position: absolute;
          display: block;
          width: 47.42%;
          top: -1.68%;
          right: 83.28%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(160px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube2{
          position: absolute;
          display: block;
          width: 69.06%;
          top: 20.5%;
          left: 15.47%;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(170px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
        .cube3{
          position: absolute;
          display: block;
          width: 94.45%;
          top: -8.42%;
          left: 35%;
          transition: transform 800ms ease-out;
          img{
            position: relative;
            width: 100%;
            opacity: 0;
            transform: translateY(100px);
            animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
          }
        }
      }

      #product-top-back{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      @media screen and (max-width: 800px) {
        #product-top-back{
          display: none;
        }
        background: #0F192E url(../img/prod_bg1_m.png) no-repeat 50% 50%;
        background-size: cover;
      }
      .product-top-content{
        position: relative;
        display: block;
        text-align: center;
        color: #B8C0CF;
        opacity: 0;
        transform: translateY(100px);
        animation: showContent 0.8s forwards cubic-bezier(.17,.67,.42,.96);
        animation-delay: 0.5s;
        @media screen and (max-width: 800px) {
          padding: 0 16px;
          box-sizing: border-box;
          width: 100%;
          text-align: left;
          position: relative;
          padding-top: 45px;
        }

        #product-tumbler{
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 295px;
          height: 48px;
          box-sizing: border-box;
          margin: 0 auto 56px;
          background: #FFFFFF;
          backdrop-filter: blur(95px);
          -webkit-backdrop-filter: blur(95px);
          border-radius: 16px;
          padding: 6px;
          @media screen and (max-width: 800px) {
            width: 270px;
            height: 45px;
            margin: 0;
            margin-bottom: 34px;
          }
          z-index: 11;

          .product-tumbler{
            position: relative;
            display: block;
            width: 136px;
            &:nth-child(2){
              width: 145px;
            }
            height: 36px;
            //opacity: 0.4;
            border-radius: 12px;
            text-align: center;
            line-height: 36px;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: -0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #5B6076;
            cursor: pointer;
            .tooltip{
              position: absolute;
              display: block;
              visibility: hidden;
              right: 19px;
              top: 48px;
              width: 315px;
              height: 72px;
              background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.1) 0%, rgba(243, 243, 243, 0.08) 100%);
              box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.16);
              backdrop-filter: blur(47.5px);
              border-radius: 16px 0px 16px 16px;
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              text-align: left;
              color: #B8C0CF;
              padding: 16px;
              box-sizing: border-box;
              &.white-tooltip{
                background: linear-gradient(94.71deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
                box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.07);
                backdrop-filter: blur(47.5px);
                color: #5B6076;
              }
            }
            &:nth-child(2){
              .tooltip{
                right: -288px;
                border-radius: 0px 16px 16px 16px;
              }
            }
            &:hover{
              .tooltip {
                visibility: visible;
              }
            }
            @media screen and (max-width: 800px) {
              .tooltip{
                display: none;
              }
              width: 129px;
              height: 33px;
              line-height: 33px;
              font-size: 12px;
            }
            img{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: 0;
              margin-left: 3px;
            }
            &.active{
              background: #4E5369;
              color: #fff;
            }
          }
        }

        b{
          position: relative;
          display: block;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-feature-settings: 'pnum' on, 'lnum' on;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          margin-bottom: 24px;
          padding: 10px;
          @media screen and (max-width: 800px) {
            padding: 0;
            font-size: 52px;
            line-height: 56px;
            text-align: left;
            &.mobile-wordbreak{
              word-break: break-all;
              margin-right: -10px;
            }
            br{
              content: ' ';
              display: inline-block;
              width: 6px;
            }
          }
        }
        .product-top-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 40px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100%;
            }
            &:nth-child(2){
              width: 220px;
              color: #0F192E;
              background: #fff;
              backdrop-filter: blur(95px);
              -webkit-backdrop-filter: blur(95px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .container{
      position: relative;
      display: block;
      margin: 120px 40px;
      @media screen and (max-width: 800px) {
        margin: 80px 16px;
      }
      .section-title{
        position: relative;
        display: block;
        padding: 0 10px;
        font-weight: 500;
        font-size: 64px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.06em;
        background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 56px;
        @media screen and (max-width: 800px) {
          font-size: 32px;
          line-height: 32px;
          margin-bottom: 40px;
        }
      }

      #why-items{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
          display: block;
        }
        .why-item{
          width: calc(33.33% - 16px);
          @media screen and (max-width: 800px) {
            width: 100%;
            margin-bottom: 32px;
            img{
              width: 32px;
            }
          }
          strong{
            position: relative;
            display: block;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-top: 16px;
            margin-bottom: 8px;
            @media screen and (max-width: 800px) {
              margin-top: 10px;
            }
          }
        }
      }
    }

    &#partner-programs{
      .container{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F5F6F7 url(../img/partners_bg.jpg) no-repeat 50% 50%;
        background-size: cover;
        border-radius: 40px;
        height: 448px;
        text-align: center;
        @media screen and (max-width: 800px) {
          display: block;
          margin: 0;
          height: auto;
          background: #F5F6F7 url(../img/mobile_partners_program.webp) no-repeat 0 100%;
          background-size: 100% auto;
          border-radius: 16px 16px 0px 0px;
          padding: 80px 16px 248px;
        }
        .partner-programs-info{
          b{
            position: relative;
            display: block;
            font-weight: 500;
            font-size: 64px;
            line-height: 100%;
            letter-spacing: -0.06em;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 24px;
            @media screen and (max-width: 800px) {
              font-size: 32px;
              line-height: 32px;
            }
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 220px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            margin: 0 auto;
            margin-top: 32px;
            @media screen and (max-width: 800px) {
              font-size: 16px;
              line-height: 56px;
              width: 202px;
              height: 56px;
            }
          }
        }
      }
    }

    &#home-about{
      #home-about-items{
        display: block;
        .about-pagination{
          display: none;
          overflow: hidden;
          .swiper-pagination-progressbar-fill{
            background: #b3b8bf;
            width: 100%;
            position: absolute;
            left: 0;
            height: 4px;
            display: block;
            border-radius: 2px;
            transform-origin: left top;
          }
        }
        &.slider-block{
          .swiper-wrapper{
            display: block;
            white-space: nowrap;

            .home-about-item{
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 288px;
              height: 286px;
              white-space: normal;
              margin-right: 26px;
              span{
                font-size: 18px;
              }
            }
            .home-about-item-link{
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 288px;
              margin-right: 16px;
              height: 286px;
              white-space: normal;
            }
          }
          .about-pagination{
            position: relative;
            display:block;
            margin: 0 auto;
            margin-top: 40px;
            width: 192px;
            height: 4px;
            background: #EBEBEB;
            border-radius: 2px;
          }
        }
        .swiper-wrapper {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;

          .home-about-item {
            position: relative;
            display: block;
            width: calc(33.3333% - 16px);
            height: 310px;
            border-radius: 16px;

            span {
              position: absolute;
              width: 100%;
              text-align: center;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              bottom: 28px;
            }
          }

          .home-about-item-link {
            position: relative;
            display: block;
            width: calc(33.3333% - 16px);
            height: 310px;
            text-decoration: none;
            .home-about-item-link-data{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border-radius: 16px;
              background: url(../img/about_item_link.png) no-repeat 50% 50%;
              background-size: cover;
              text-decoration: none;

              span {
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;

                svg {
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  top: -1px;
                }
              }

              &:hover {
                span {
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;

                  svg {
                    path {
                      fill: #FF9400;
                    }

                    linearGradient {
                      stop {
                        stop-color: #FF9400;
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }

    &.mobile-solution-way{
      display: none;
      @media screen and (max-width: 800px) {
        display: block;


      }



      .container {
        position: relative;
        display: block;
        margin: 80px 16px;

        .way-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 56px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100%;
            }
            &:nth-child(2){
              width: 262px;
              color: #0F192E;
              background: #EEF0F1;
              backdrop-filter: blur(95px);
              -webkit-backdrop-filter: blur(95px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }
        .mobile-solution-way-items{
          .way-pagination{
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0 auto;
            margin-top: 40px;
            width: 192px;
            height: 4px;
            background: #EBEBEB;
            border-radius: 2px;
            .swiper-pagination-progressbar-fill{
              background: #b3b8bf;
              width: 100%;
              position: absolute;
              left: 0;
              height: 4px;
              display: block;
              border-radius: 2px;
              transform-origin: left top;
            }
          }

          .swiper-wrapper{
            white-space: nowrap;
            display: block;
            .mobile-solution-way-item{
              position: relative;
              display: inline-block;
              vertical-align: top;
              white-space: normal;
              width: 288px;
              font-size: 14px;
              margin-right: 16px;

              &:first-child{
                padding-left: 40px;
                &:before{
                  position: absolute;
                  display: block;
                  content: "";
                  width: 24px;
                  height: 24px;
                  background: #FFFFFF url(../img/path/round.svg) no-repeat 50% 50%;
                  background-size: 10px 10px;
                  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                  border-radius: 200px;
                  left: 0;
                  top: 0;
                }
              }

              b{
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 18px;
                line-height: 120%;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-bottom: 8px;
              }
              span{
                font-feature-settings: "pnum" on,"lnum" on;
                background: linear-gradient(92.45deg,#f60,#ff9400);
                border-radius: 8px;
                color: #fff;
                display: block;
                font-size: 14px;
                font-weight: 600;
                height: 24px;
                letter-spacing: -.02em;
                line-height: 24px;
                margin-bottom: 16px;
                position: relative;
                text-align: center;
                width: 24px;
                &:after{
                  position: absolute;
                  display: block;
                  width: 248px;
                  height: 1px;
                  top: 12px;
                  left: 40px;
                  content: "";
                  background: url(../img/mobile_punctir.svg) no-repeat 0 0;
                  background-size: 100% 100%;
                }
              }
            }
          }
        }
      }
    }

    &.solution-way{
      @media screen and (max-width: 800px) {
        display: none;
      }

      .container{
        position: relative;
        display: block;
        min-height: 500px;
        margin: 120px 40px;
        .way-path{
          position: absolute;
          display: block;
          left:calc(50% - 53px);
          top: 41px;
          .way-path-punctir{
            opacity: 0;
            transition: all 300ms linear;
            &.active{
              opacity: 1;
            }
          }
          .way-dot{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            background: #fff;
            box-shadow: 0px 8px 23px rgba(0, 0, 0, 0.08);
            top:-20px;
            left: -10px;
            opacity: 0;
            transition: all 300ms linear;
            &.active{
              opacity: 1;
            }
            &.invisible{
              visibility: hidden;
            }
            &:last-child{
              top: 100%;
            }
          }
        }
        .way-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 56px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100%;
            }
            &:nth-child(2){
              width: 262px;
              color: #0F192E;
              background: #EEF0F1;
              backdrop-filter: blur(95px);
              -webkit-backdrop-filter: blur(95px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }


        @media screen and (max-width: 800px) {
          margin: 80px 20px;
        }

        //.section-title{
        //  position: absolute;
        //  top:120px;
        //  width: 100%;
        //}
        .section-title-parent{
          position: absolute;
          top:120px;
          width: 100%;
          height: 360px;
          .section-title{
            position: sticky;
            top: calc(50vh - 50px);
            width: 100%;
          }
        }

        .download-btn{
          align-items: center;
          background: linear-gradient(92.45deg,#f60,#ff9400);
          border-radius: 16px;
          color: #fff;
          display: flex;
          font-size: 18px;
          height: 64px;
          justify-content: center;
          letter-spacing: -.02em;
          line-height: 25px;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: 261px;
          margin: 56px auto 0;
          @media screen and (max-width: 800px) {
            width: 100%;
            height: 56px;
            line-height: 56px;
          }

        }

        .solution-way-items{
          position: relative;
          display: block;
          max-width: 998px;
          width: calc(100% - 20px);
          margin: 0 auto;
          .solution-way-item{
            position: relative;
            display: block;
            box-sizing: border-box;
            width: 100%;
            max-width: 390px;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            box-sizing: border-box;
            color: #5B6076;
            padding: 24px;
            background: #F5F6F7;
            border-radius: 20px;
            margin-bottom: 37px;
            opacity: 0;
            transition: all 300ms linear;
            &.active{
              opacity: 1;
            }
            &:nth-child(2){
              margin-left: calc(100% - 390px);
            }
            &:nth-child(4){
              margin-left: calc(100% - 390px);
            }
            &:nth-child(6){
              margin-left: calc(100% - 390px);
            }
            b{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 8px;
            }
            span{
              font-feature-settings: "pnum" on,"lnum" on;
              background: linear-gradient(92.45deg,#f60,#ff9400);
              border-radius: 11px;
              color: #fff;
              display: block;
              font-size: 18px;
              font-weight: 600;
              height: 32px;
              letter-spacing: -.02em;
              line-height: 36px;
              margin-bottom: 19px;
              position: relative;
              text-align: center;
              width: 32px;
            }
          }
        }

      }

      &.four-blocks{
        .container{
          .way-path{
            img.way-path-punctir{
              margin-left: -191px;
            }
            .way-dot:last-child {
              top: 100%;
              margin-top: -29px;
              margin-left: 72px;
            }
          }
          .section-title-parent{
            height: 640px;
          }
        }
      }
      &.six-blocks{
        .container{
          .way-path{
            img.way-path-punctir{
              margin-left: -191px;
            }
            .way-dot:last-child {
              top: 100%;
              margin-top: -52px;
              margin-left: 72px;
            }
          }
          .section-title-parent{
            height: 1240px;
          }
        }
      }

    }


    &#form14day{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(../img/14day.jpg) no-repeat 50% 50%;
      background-size: cover;

      &.gray{
        background: url(../img/14day_gray.webp) no-repeat 50% 50%;
        background-size: cover;
        .container{
          color: #5B6076;
          .section-title{
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          #form-14{
            background: #fff;
            span{
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            input{
              color: #7a7f8f;
              border-color: rgba(89, 96, 119, 0.1);
              &:hover{
                border: 1.5px solid #B3B8BF;
              }
              &:focus{
                border: 1.5px solid rgba(89, 96, 119, 0.4);
              }
              &::-webkit-input-placeholder{
                color: #7a7f8f;
              }
            }
            textarea{
              color: #7a7f8f;
              border-color: rgba(89, 96, 119, 0.1);
              &:focus{
                border: 1.5px solid rgba(89, 96, 119, 0.4);
              }
              &:hover{
                border: 1.5px solid #B3B8BF;
              }
              &::-webkit-input-placeholder{
                color: #7a7f8f;
              }
            }
            small{
              color: #B3B8BF;
            }
            .input-block{
              &.error{
                small{
                  display: block;
                }
                input{
                  background: rgba(255, 246, 245, 1);
                  border: 1px solid #FF735D;
                }
              }

            }
          }
        }
      }
      &.white{
        background: url(../img/14day_white.png) no-repeat 50% 50%;
        background-size: 100% 100%;
        .container{
          color: #5B6076;
          .section-title{
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          #form-14{
            background: #F5F6F7;
            span{
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            input{
              color: #7a7f8f;
              background: #fff;
              border-color: rgba(89, 96, 119, 0.1);
              &:hover{
                border: 1.5px solid #B3B8BF;
              }
              &:focus{
                border: 1.5px solid rgba(89, 96, 119, 0.4);
              }
              &::-webkit-input-placeholder{
                color: #7a7f8f;
              }
            }
            small{
              color: #B3B8BF;
            }
          }
        }
      }

      .container{
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #B8C0CF;
        @media screen and (max-width: 800px) {
          text-align: left;
        }

        .form-description{
          max-width: 605px;
          margin: 0 auto;
          @media screen and (max-width: 800px) {
            br{
              content: " ";
              display: inline-block;
              width: 4px;
            }
          }
        }


        .section-title{
          position: relative;
          display: block;
          font-weight: 500;
          font-size: 64px;
          margin-bottom: 24px;
          line-height: 100%;
          text-align: center;
          padding: 0;
          letter-spacing: -0.06em;
          white-space: nowrap;
          background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 32px;
            text-align: left;
            padding: 0;
          }
        }

        #form-14{
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          max-width: 592px;
          box-sizing: border-box;
          margin: 0 auto;
          margin-top: 56px;
          min-height: 300px;
          background: linear-gradient(94.71deg, rgba(255, 255, 255, 0.0336) 0%, rgba(255, 255, 255, 0.0896) 101.02%);
          backdrop-filter: blur(54px);
          -webkit-backdrop-filter: blur(54px);
          border-radius: 16px;
          padding: 32px;
          @media screen and (max-width: 800px) {
            padding: 24px 16px;
          }
          .select-file-block{
            position: relative;
            width: 100%;
            display: flex;
            margin-top: 16px;
            margin-bottom: 16px;
            justify-content: space-between;
            @media screen and (max-width: 800px) {
              flex-direction: column;
            }
            .select-file-btn{
              position: relative;
              display: block;
              padding: 0px 16px;
              font-size: 18px;
              width: 334px;
              height: 56px;
              line-height: 56px;
              background: #F5F6F7;
              border-radius: 16px;
              box-sizing: border-box;
              cursor: pointer;
              &:hover{
                background: #E3E6E8;
              }
              @media screen and (max-width: 800px) {
                font-size: 16px;
                width: 100%;
                text-align: center;
              }
              img{
                position: relative;
                display: inline-block;
                top: -2px;
                vertical-align: middle;
              }
            }
            small{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 14px;
              line-height: 56px;
              padding: 0;
              width: auto;
              white-space: nowrap;
              @media screen and (max-width: 800px) {
                font-size: 12px;
              }
            }
          }
          .input-block{
            position: relative;
            display: block;
            width: 100%;
            &.half-block{
              display: inline-block;
              width: calc(50% - 8px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }

            }
            &.select-input-block{
              width: 100%;
              margin-bottom: 12px;
              padding: 0 16px;
              height: 64px;
              border: 1px solid #EBEBEB;
              border-radius: 16px;
              box-sizing: border-box;

              .select-title{
                width: 100%;
                height: 62px;
                line-height: 62px;
                color: #5B6076;
                opacity: 0.8;
                cursor: pointer;
                &:after{
                  position: absolute;
                  display: block;
                  width: 24px;
                  height: 24px;
                  right: 20px;
                  top: 20px;
                  content: "";
                  background: url(../img/blog_select.svg) no-repeat 50% 50%;
                }
              }

              &:hover{
                border: 1px solid #B3B8BF;
              }
              &.active{
                border: 1px solid #5B6076;
                .select-items{
                  position: absolute;
                  left: 0;
                  z-index: 10;
                  top: 58px;
                  width: 100%;
                  box-sizing: border-box;
                  display: flex;
                  gap: 8px;
                  flex-direction: column;
                  padding: 24px;
                  background: #FFFFFF;
                  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
                  border-radius: 16px;
                  .select-item{
                    position: relative;
                    display: block;
                    line-height: 24px;
                    font-weight: 500;
                    font-size: 16px;
                    cursor: pointer;
                    padding-left: 28px;
                    &:before{
                      position: absolute;
                      display: block;
                      content: "";
                      left: 0;
                      top: 2px;
                      width: 16px;
                      height: 16px;
                      background: #F5F6F7;
                      border: 1.5px solid #EBEBEB;
                      border-radius: 4px;
                      box-sizing: border-box;
                    }
                    &.active{
                      &:before {
                        background: url(../img/select_check.svg) no-repeat 50% 50%;
                        border: 0;
                      }
                    }
                    &:hover{
                      color: #0F192E;
                    }
                  }
                }
              }

              .select-items{
                display: none;
              }
            }
            small{
              position: relative;
              text-align: left;
              display: none;
              font-weight: 600;
              font-size: 14px;
              line-height: 140%;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              margin-top: 8px;
              margin-bottom: 12px;
              color: #FF735D;
            }
            &.error{
              small{
                display: block;
              }
              input{
                background: rgba(255, 246, 245, 0.2);
                border: 1px solid #FF735D;
              }
            }
          }

          span{
            position: relative;
            display: block;
            width: 100%;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            text-align: center;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 24px;
            @media screen and (max-width: 800px) {
              br{
                position: relative;
                display: inline-block;
                width: 6px;
                content: " ";
              }
            }
          }
          input{
            position: relative;
            display: block;
            width: 100%;
            margin-bottom: 16px;
            padding: 0 16px;
            height: 64px;
            border-radius: 16px;
            border: 1.5px solid rgba(255, 255, 255, 0.16);
            box-sizing: border-box;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #fff;
            background: none;
            outline: none;
            font-family: 'TT Wellingtons';
            @media screen and (max-width: 800px) {
              height: 56px;
            }
            &::-webkit-input-placeholder{
              color: #B8C0CF;
            }
            &:focus{
              border: 1.5px solid rgba(255, 255, 255, 0.92);
            }
          }
          textarea{
            position: relative;
            display: block;
            width: 100%;
            margin-bottom: 16px;
            padding: 16px 16px;
            height: 94px;
            border-radius: 16px;
            border: 1.5px solid #eeeff1;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #5b6176;
            background: #fff;
            outline: none;
            resize: none;
            font-family: 'TT Wellingtons';
            @media screen and (max-width: 800px) {
              height: 94px;
            }
            &::-webkit-textarea-placeholder{
              color: #5b6176;
            }
            &:focus{
              border: 1.5px solid rgba(255, 255, 255, 0.92);
            }
          }
          button{
            box-sizing: border-box;
            outline: none;
            border: 0;
            width: 100%;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            backdrop-filter: blur(95px);
            -webkit-backdrop-filter: blur(95px);
            border-radius: 16px;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            cursor: pointer;
            margin-top: 16px;
            margin-bottom: 16px;
            font-family: 'TT Wellingtons';

            @media screen and (max-width: 800px) {
              font-size: 16px;
              height: 56px;
            }
          }
          small{
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.02em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #636979;
            @media screen and (max-width: 800px) {
              position: relative;
              display: block;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              width: 311px;
              margin: 0 auto;
              br{
                position: relative;
                display: inline-block;
                width: 4px;
                content: " ";
              }
            }

          }

        }
      }
      @media screen and (max-width: 800px) {
        padding: 1px 0;
        display: block;
        .container{
          .section-title{
            white-space: normal;
            br{
              display: inline-block;
              width: 10px;
              content: '';
            }
          }
          #form-14{
            margin-top: 40px;
            width: auto;
          }
        }
      }

    }

    &.what-is-it{
      .container{
        position: relative;
        display: block;
        margin: 40px;
        border-radius: 24px;
        background: #F5F6F7;
        padding: 80px 0;
        overflow: hidden;
        @media screen and (max-width: 800px) {
          margin: 0;
          padding: 56px 16px;
        }
        .download-btn{
          align-items: center;
          background: linear-gradient(92.45deg,#f60,#ff9400);
          border-radius: 16px;
          color: #fff;
          display: flex;
          font-size: 18px;
          height: 64px;
          justify-content: center;
          letter-spacing: -.02em;
          line-height: 25px;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: 261px;
          margin: 56px auto 0;
          @media screen and (max-width: 800px) {
            width: 100%;
            height: 56px;
            line-height: 56px;
          }
        }

        &:after{
          position: absolute;
          display: block;
          content: '';
          width: 118px;
          height: 562px;
          background: url(../img/about1.png) no-repeat 50% 50%;
          background-size: 100% auto;
          left: 0;
          top: calc(50% - 281px);
          transform: translateY(73px);
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        &:before{
          position: absolute;
          display: block;
          content: '';
          width: 214px;
          height: 100%;
          background: url(../img/about2.png) no-repeat 50% 50%;
          background-size: 100% auto;
          right: 0;
          top: 0;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        .section-title{
          margin-bottom: 24px;
        }
        .what-text{
          width: calc(100% - 200px);
          max-width: 592px;
          margin: 0 auto;
          p{
            margin: 8px 0;
          }
          @media screen and (max-width: 800px) {
            width: auto;
            text-align: left;
          }
        }

      }
      &.dark-color{
        .container{
          background: url(../img/dark_about.jpg) no-repeat 50% 0;
          background-size: cover;
          text-align: center;
          color: #B8C0CF;
          .section-title{
            background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          &:after{
            display: none;
          }
          &:before{
            display: none;
          }
        }
      }
    }

    &.tech-char{
      overflow: hidden;
      &:before{
        position: absolute;
        display: block;
        content: '';
        width: 1160px;
        height: 913px;
        background: url(../img/tech_left.png) no-repeat 0 0;
        background-size: contain;
        left: calc(50% - 1640px);
        top: 202px;
      }
      &.no-before{
        &:before{
          display: none;
        }
      }
      &:after{
        position: absolute;
        display: block;
        content: '';
        width: 1198px;
        height: 1025px;
        background: url(../img/tech_right.png) no-repeat 0 0;
        background-size: contain;
        left: calc(50% + 413px);
        top: -393px;
        z-index: -1;
      }
      .container{
        .section-title{
          @media screen and (max-width: 800px) {
            margin-bottom: 24px;
          }
        }
        .tech-char-buttons{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 16px;
          margin-top: 56px;
          @media screen and (max-width: 800px) {
            width: 100%;
            display: block;
          }
          a{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 261px;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            border-radius: 16px;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            letter-spacing: -0.02em;
            text-decoration: none;
            color: #FFFFFF;
            @media screen and (max-width: 800px) {
              height: 56px;
              margin-bottom: 12px;
              font-size: 16px;
              width: 100%;
            }
            &:nth-child(2){
              width: 262px;
              color: #0F192E;
              background: #EEF0F1;
              backdrop-filter: blur(95px);
              -webkit-backdrop-filter: blur(95px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }

        .tech-char-items{
          position: relative;
          display: flex;
          margin: 0 auto;
          max-width: 960px;
          flex-wrap: wrap;
          @media screen and (max-width: 800px) {
            display: block;
          }

          .tech-char-item{
            position: relative;
            padding: 24px;
            box-sizing: border-box;
            width: 33.3333%;
            @media screen and (max-width: 800px) {
              width: 100%;
              padding: 24px 0;
              border: 0 !important;
              border-bottom: 1.5px solid #EBEBEB !important;
              &:last-child{
                border-bottom: 0 !important;
              }
            }
            &:nth-child(1){
              border-bottom: 1.5px solid #EBEBEB;
              border-right: 1.5px solid #EBEBEB;
              &:after{
                position: absolute;
                display: block;
                content: "";
                width: 24px;
                height: 24px;
                background: #fff;
                z-index: 1;
                right: -12px;
                bottom: -12px;
              }
            }
            &:nth-child(2){
              border-bottom: 1.5px solid #EBEBEB;
              border-right: 1.5px solid #EBEBEB;
              &:after{
                position: absolute;
                display: block;
                content: "";
                width: 24px;
                height: 24px;
                background: #fff;
                z-index: 1;
                right: -12px;
                bottom: -12px;
              }
            }
            &:nth-child(7){
              border-top: 1.5px solid #EBEBEB;
              border-right: 1.5px solid #EBEBEB;
              &:after{
                position: absolute;
                display: block;
                content: "";
                width: 24px;
                height: 24px;
                background: #fff;
                z-index: 1;
                right: -12px;
                top: -12px;
              }
            }
            &:nth-child(8){
              border-top: 1.5px solid #EBEBEB;
              border-right: 1.5px solid #EBEBEB;
              &:after{
                position: absolute;
                display: block;
                content: "";
                width: 24px;
                height: 24px;
                background: #fff;
                z-index: 1;
                right: -12px;
                top: -12px;
              }
            }
            &:nth-child(3){
              border-bottom: 1.5px solid #EBEBEB;
            }
            &:nth-child(4){
              border-right: 1.5px solid #EBEBEB;
            }
            &:nth-child(6){
              border-left: 1.5px solid #EBEBEB;
            }
            &:nth-child(9){
              border-top: 1.5px solid #EBEBEB;
            }

            b{
              position: relative;
              display: block;
              margin-bottom: 8px;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              @media screen and (max-width: 800px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    &.gray-blocks{
      .container{
        position: relative;
        display: block;
        margin: 120px 40px;
        @media screen and (max-width: 800px) {
          margin: 80px 16px;
        }


        .gray-blocks-list{
          display: block;
          flex-wrap: wrap;
          justify-content: space-between;
          .gray-blocks-pagination{
            position: relative;
            display: none;
            overflow: hidden;
            margin: 0 auto;
            margin-top: 40px;
            width: 192px;
            height: 4px;
            background: #EBEBEB;
            border-radius: 2px;
            .swiper-pagination-progressbar-fill{
              background: #b3b8bf;
              width: 100%;
              position: absolute;
              left: 0;
              height: 4px;
              display: block;
              border-radius: 2px;
              transform-origin: left top;
            }
          }
          &.slider-block{
            .gray-blocks-pagination{
              display: block;
            }
            .swiper-wrapper {
              position: relative;
              display: block;
              white-space: nowrap;
              .gray-block {
                display: inline-block;
                vertical-align: top;
                flex-direction: column;
                white-space: normal;
                width: 288px;
                height: auto;
                margin-left: 0;
                margin-right: 16px;
                &.with-check {
                  width: 288px;
                }
              }
            }
          }
          .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .gray-block {
              position: relative;
              display: block;
              padding: 24px;
              box-sizing: border-box;
              border-radius: 16px;
              background: #F5F6F7;
              width: calc(33.33% - 12px);
              font-weight: 500;
              font-size: 16px;
              line-height: 160%;
              color: #5B6076;
              margin-bottom: 16px;
              span{
                max-width: 341px;
              }
              @media screen and (max-width: 800px) {
                margin-bottom: 0;
                font-size: 14px;
              }


              &.with-check {
                width: calc(50% - 12px);

                b {
                  margin-bottom: 24px;
                }

                .gray-block-check {
                  padding-left: 48px;
                  background: url(../img/check_icon.svg) no-repeat 0 0;
                }

                &.active {
                  .gray-block-check {
                    background: url(../img/check_icon_active.svg) no-repeat 0 0;
                    @media screen and (max-width: 800px) {
                      background-size: 25px 24px;
                    }
                  }
                }
              }

              b {
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 20px;
                line-height: 120%;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-bottom: 8px;
                @media screen and (max-width: 800px) {
                  font-size: 18px;
                }
              }
            }

            .gray-block-empty {
              position: relative;
              display: block;
              width: calc(33.33% - 12px);
            }
          }

        }
        .download-btn{
          align-items: center;
          background: linear-gradient(92.45deg,#f60,#ff9400);
          border-radius: 16px;
          color: #fff;
          display: flex;
          font-size: 18px;
          height: 64px;
          justify-content: center;
          letter-spacing: -.02em;
          line-height: 25px;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: 261px;
          margin: 56px auto;
          @media screen and (max-width: 800px) {
            width: 100%;
            height: 56px;
            line-height: 56px;
          }
        }
      }
    }

    &#care-blocks{
      margin: 0 40px;
      @media screen and (max-width: 800px) {
        margin: 0;
      }
      .container{
        position: relative;
        display: block;
        margin: 80px 40px 64px;
        @media screen and (max-width: 800px) {
          margin: 80px 16px 64px;
        }
        #care-blocks-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .gray-block-empty{
            width: calc(33.33% - 12px);
          }
          @media screen and (max-width: 800px) {
            display: block;
          }
          .gray-block{
            position: relative;
            display: block;
            padding: 24px;
            box-sizing: border-box;
            border-radius: 16px;
            background: #F5F6F7;
            width: calc(33.33% - 12px);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #5B6076;
            margin-bottom: 16px;
            span{
              max-width: 341px;
            }
            @media screen and (max-width: 800px) {
              width: 100%;
              //font-size: 14px;
              //line-height: 22px;
            }
            &.with-check{
              width: calc(50% - 8px);
              @media screen and (max-width: 800px) {
                width: 100%;
              }
              b{
                margin-bottom: 24px;
              }
              .gray-block-check{
                padding: 4px 0;
                padding-left: 48px;
                background: url(../img/check_icon.svg) no-repeat 0 0;
                margin-bottom: 16px;
                @media screen and (max-width: 800px) {
                  background-size: 24px 24px;
                  padding-left: 36px;
                  padding-top: 0;
                }

                &:last-child{
                  margin-bottom: 0;
                }
              }
              &.active{
                .gray-block-check{
                  background: url(../img/check_icon_active.svg) no-repeat 0 0;
                  @media screen and (max-width: 800px) {
                    background-size: 24px 24px;
                    padding-left: 36px;
                    padding-top: 0;
                  }
                }
              }
            }
            b{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 8px;
              @media screen and (max-width: 800px) {
                font-size: 18px;
              }
            }
          }
          .gray-block-empty{
            position: relative;
            display: block;
            width: calc(33.33% - 12px);
          }

        }
      }

      &.gray-bg{
        overflow: hidden;
        background: #F5F6F7;
        border-radius: 24px;
        .container{
          #care-blocks-list{
            .gray-block{
              background: #fff;
              .gray-block-check{
                background: url(../img/check_icon_g.svg) no-repeat 0 0;
                @media screen and (max-width: 800px) {
                  background-size: 24px 24px;
                  padding-left: 36px;
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }

    &#red-number-blocks{
      .container{
        position: relative;
        display: block;
        margin: 120px 40px;
        @media screen and (max-width: 800px) {
          margin: 80px 16px;
        }
        #red-number-blocks-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @media screen and (max-width: 800px) {
            flex-direction: column;
          }
          .red-number-block{
            position: relative;
            display: block;
            box-sizing: border-box;
            width: calc(33.33% - 12px);
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #5B6076;
            margin-bottom: 16px;
            @media screen and (max-width: 800px) {
              margin-bottom: 24px;
            }
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            b{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 8px;
              @media screen and (max-width: 800px) {
                font-size: 18px;
              }
            }
            span{
              font-feature-settings: "pnum" on,"lnum" on;
              background: linear-gradient(92.45deg,#f60,#ff9400);
              border-radius: 11px;
              color: #fff;
              display: block;
              font-size: 18px;
              font-weight: 600;
              height: 32px;
              letter-spacing: -.02em;
              line-height: 36px;
              margin-bottom: 19px;
              position: relative;
              text-align: center;
              width: 32px;


              @media screen and (max-width: 800px) {
                border-radius: 8px;
                font-size: 14px;
                height: 24px;
                line-height: 26px;
                margin-bottom: 16px;
                width: 24px;
              }
            }
          }
          .gray-block-empty{
            position: relative;
            display: block;
            width: calc(33.33% - 12px);
          }
        }
      }
    }

    &#icon-items-blocks{
      &.about-icon-items-blocks{
        .icon-items-block{
          margin-bottom: 40px;
        }
      }
      .container{
        position: relative;
        display: block;
        margin: 120px 40px;
        @media screen and (max-width: 800px) {
          margin: 80px 16px;
        }
        #icon-items-blocks-list{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .icon-items-block{
            position: relative;
            display: block;
            box-sizing: border-box;
            width: calc(33.33% - 12px);
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            color: #5B6076;
            margin-bottom: 16px;
            @media screen and (max-width: 800px) {
              width: 100%;
              margin-bottom: 32px;
            }
            b{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 8px;
            }
            img{
              position: relative;
              display: block;
              margin-bottom: 16px;
              @media screen and (max-width: 800px) {
                width: 32px;
              }
            }
          }
          .gray-block-empty{
            position: relative;
            display: block;
            width: calc(33.33% - 12px);
          }
        }
      }
    }

    &#support24{
      margin: 80px auto;
      overflow: hidden;
      &.dark{
        .container{
          padding: 80px 101px;
          height: 410px;
          background: #0F192E;
          border-radius: 24px;
          box-sizing: border-box;
          @media screen and (max-width: 800px) {
            padding: 80px 16px;
            margin: 0 auto;
            height: auto;
          }

          img{
            @media screen and (max-width: 800px) {
              margin: -32px 0 0;
            }

          }

          .support24-text{
            padding-left: 0;
            color: #B8C0CF;
            b{
              background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          &:before{
            position: absolute;
            width: 555px;
            height: 555px;
            left: calc(50% - 555px/2);
            top: 0px;
            content: "";
            background: rgba(200, 217, 247, 0.26);
            opacity: 0.7;
            filter: blur(300px);
          }
        }
      }
      .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
          flex-direction: column-reverse;
        }
        img{
          width: 324px;

          @media screen and (max-width: 800px) {
            margin: -32px -39px 0 0;
            display: none;
          }
        }
        .support24-text{
          width: calc(100% - 300px);
          max-width: 561px;
          padding-left: 60px;
          color: #5B6076;
          font-size: 16px;
          line-height: 26px;
          img{
            display: none;
            @media screen and (max-width: 800px) {
              display: block;
              margin: 0 auto;
              position: relative;
              left: 20px;
            }
          }
          @media screen and (max-width: 800px) {
            width: auto;
            padding: 0;
          }
          b{
            position: relative;
            display: block;
            font-weight: 500;
            font-size: 64px;
            line-height: 100%;
            letter-spacing: -0.06em;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 24px;
            @media screen and (max-width: 800px) {
              font-size: 32px;
            }
          }
        }
      }
    }

    &#use-products{
      background: #F5F6F7;
      margin-bottom: 0;
      padding: 120px 0;
      @media screen and (max-width: 800px) {
        padding: 80px 0;
      }

      &.not-full{
        margin: 0 40px;
        border-radius: 24px;
        padding: 80px 0;
        @media screen and (max-width: 800px) {
          border-radius: 0;
          padding: 80px 0;
          margin: 0;
          overflow: hidden;
        }
      }

      .container{
        margin: 0 40px;
        @media screen and (max-width: 800px) {
          margin: 0 16px;
        }
        #product-items{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 16px;
          @media screen and (max-width: 800px) {
            flex-direction: column;
          }
          .product-item{
            width: calc(50% - 8px);
            background: #FFFFFF;
            border-radius: 16px;
            padding: 24px;
            box-sizing: border-box;
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            strong{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-top: 16px;
              margin-bottom: 8px;
              @media screen and (max-width: 800px) {
                margin-top: 10px;
                font-size: 18px;
              }
            }
            img{
              @media screen and (max-width: 800px) {
                width: 32px;
              }
            }
            &.video-item{
              position: relative;
              display: none;
              overflow: hidden;
              &.about-video{
                display: block;
              }
              width: 100%;
              height: 49vw;
              background: url(../img/video.jpg) no-repeat 50% 50%;
              background-size: cover;
              iframe{
                position: absolute;
                //display: none;
                left: 0;
                top: 0;
              }
              &.play{
                iframe{
                  display: block;
                }
                strong{
                  color: #fff;
                }
                small{
                  color: #B3B8BF;
                  position: relative;
                }
                .video-play-btn{
                  display: none;
                }
              }
              @media screen and (max-width: 800px) {
                height: 60vw;
              }
              strong{
                display: inline-block;
                margin-top: 0;
                background: none;
                -webkit-text-fill-color: inherit;
                text-fill-color: inherit;
                -webkit-background-clip: inherit;
                background-clip: inherit;
              }
              small{
                font-weight: 500;
                font-size: 16px;
                line-height: 160%;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #B3B8BF;
                margin-left: 7px;
              }
              .video-play-btn{
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 128px;
                height: 128px;
                left: calc(50% - 64px);
                top: calc(50% - 64px);
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                box-shadow: 0px 0px 100px rgba(255, 106, 1, 0.2);
                border-radius: 90px;
                cursor: pointer;
                @media screen and (max-width: 800px) {
                  width: 64px;
                  height: 64px;
                  left: calc(50% - 32px);
                  top: calc(50% - 32px);
                  img{
                    width: 16px;
                  }
                }
              }
            }
          }
        }

      }
    }

    &#vendors{
      .container{
        @media screen and (max-width: 800px) {
          margin-top: 0;
        }

        .section-title{
          margin-bottom: 100px;
          @media screen and (max-width: 800px) {
            margin-bottom: 40px;
          }

        }
        #vendors-list{
          display: flex;
          justify-content: space-between;
          gap: 16px;
          @media screen and (max-width: 800px) {
            display: block;
          }
          .vendor-item{
            position: relative;
            display: block;
            width: calc(33.333% - 16px);
            text-align: center;
            @media screen and (max-width: 800px) {
              width: 100%;
              margin-bottom: 40px;
            }
            .vendor-item-logo{
              margin: 0 auto;
              width: 234px;
              background: #FFFFFF;
              box-shadow: 0px 0px 10px rgba(69, 89, 129, 0.06), 0px 56px 80px rgba(127, 127, 162, 0.07), 0px 36.2963px 46.8519px rgba(127, 127, 162, 0.0531481), 0px 21.5704px 25.4815px rgba(127, 127, 162, 0.0425185), 0px 11.2px 13px rgba(127, 127, 162, 0.035), 0px 4.56296px 6.51852px rgba(127, 127, 162, 0.0274815), 0px 1.03704px 3.14815px rgba(127, 127, 162, 0.0168519);
              border-radius: 16px;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 32px;
              @media screen and (max-width: 800px) {
                height: 60px;
                width: 159px;
                margin-bottom: 24px;
                img{
                  width: 110px;
                }
              }
            }
          }
        }
      }
    }

    &#documentation{
      .container{
        #documentation-list{
          display: flex;
          justify-content: space-between;
          gap: 16px;
          @media screen and (max-width: 800px) {
            display: block;
          }

          .documentation-item{
            position: relative;
            display: block;
            width: calc(33.333% - 16px);
            background: #F7F7F7;
            border-radius: 16px;
            padding: 32px;
            box-sizing: border-box;
            padding-bottom: 118px;
            @media screen and (max-width: 800px) {
              padding: 24px;
              width: 100%;
              margin-bottom: 16px;
              padding-bottom: 104px;
            }

            span{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              margin: 16px 0 8px;
              @media screen and (max-width: 800px) {
                font-size: 18px;
              }
            }
            a{
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 169px;
              height: 52px;
              bottom: 32px;
              left: 32px;
              color: #0F192E;
              font-weight: 600;
              text-decoration: none;
              background: #FFFFFF;
              border-radius: 16px;
              @media screen and (max-width: 800px) {
                width: calc(100% - 48px);
                left: 24px;
                bottom: 24px;
              }
            }
          }
        }
      }
    }

    &#products{
      .container{
        #products-list{
          display: block;
          .products-pagination{
            display: none;
            overflow: hidden;
            .swiper-pagination-progressbar-fill{
              background: #b3b8bf;
              width: 100%;
              position: absolute;
              left: 0;
              height: 4px;
              display: block;
              border-radius: 2px;
              transform-origin: left top;
            }
          }
          &.slider-block{
            .swiper-wrapper{
              display: block;
              white-space: nowrap;

              .product{
                position: relative;
                display: inline-block;
                width: 288px;
                height: 400px;
                white-space: normal;
                margin-right: 16px;
                &:last-child{
                  margin-right: 26px;
                }


                a{
                  position: absolute;
                  display: block;
                  left: 24px;
                  bottom: 24px;
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  svg {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    top: -2px;
                    path{
                      fill: #FF9400;
                    }
                    linearGradient{
                      stop{
                        stop-color: #FF9400;
                      }
                    }
                  }

                }
              }
            }
            .products-pagination{
              position: relative;
              display:block;
              margin: 0 auto;
              margin-top: 40px;
              width: 192px;
              height: 4px;
              background: #EBEBEB;
              border-radius: 2px;
            }
          }
          .swiper-wrapper{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            .product{
              position: relative;
              display: block;
              border-radius: 16px;
              width: calc(25% - 16px);
              background: #F5F6F7;
              overflow: hidden;
              padding: 15.625vw 24px 24px 24px;
              box-sizing: border-box;
              a{
                display: none;
                &.desktop-more{
                  position: absolute;
                  display: block;
                  width: 3.12vw;
                  height: 3.12vw;
                  background: url(../img/product_arrow.svg) no-repeat 50% 50%;
                  background-size: contain;
                  right: 24px;
                  bottom: 24px;
                  opacity: 0;
                }
              }

              &:hover{
                a{
                  display: none;
                  &.desktop-more{
                    display: block;
                    opacity: 1;
                  }
                  @media screen and (max-width: 800px) {
                    display: block;
                    &.desktop-more{
                      display: none;
                      opacity: 0;
                    }
                  }

                }
              }

              &.product1{
                &:hover{
                  .product-image{
                    width: calc(100% - 58px);
                    height: 10.03vw;
                    left: 29px;
                    top: calc(7.8125vw - 5.015vw);
                    .product-image-block:nth-child(1){
                      width: 2.8125vw;
                      height: 2.8125vw;
                    }
                    .product-image-block:nth-child(4){
                      width: 10.39vw;
                    }
                    .product-image-block:nth-child(5){
                      width: 8.125vw;
                    }
                    .product-image-block:nth-child(6){
                      width: 2.5vw;
                    }
                    .product-image-block:nth-child(7){
                      top:6.4vw;
                    }
                    .product-image-block:nth-child(8){
                      top: 7.812vw;
                    }
                    .product-image-block:nth-child(9){
                      top: 7.5vw;
                      left: 11.718vw;
                      width: 5vw;
                      height: 1.36vw;
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: calc(100% - 48px);
                  height: 10.46vw;
                  left: 24px;
                  background: #fff;
                  border-radius: 1.32vw;
                  top: calc(7.8125vw - 5.23vw);
                  box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                  transition: all 300ms ease-out;
                  .product-image-block{
                    position: absolute;
                    display: block;
                    left: 1.25vw;
                    top: 1.25vw;
                    width: 3.125vw;
                    height: 3.125vw;
                    border-radius: 0.625vw;
                    background: linear-gradient(225deg, #F0F3F5 0%, #F0F1F2 100%);
                    transition: all 300ms ease-out;

                    &:nth-child(2){
                      width: 7.812vw;
                      height: 0.9375vw;
                      border-radius: 0.3125vw;
                      left: 5vw;
                      top: 1.25vw;
                    }
                    &:nth-child(3){
                      width: 0.9375vw;
                      height: 0.9375vw;
                      border-radius: 0.3125vw;
                      left: 13.125vw;
                      top: 1.25vw;
                    }
                    &:nth-child(4){
                      width: 12.5vw;
                      height: 0.625vw;
                      border-radius: 0.3125vw;
                      left: 5vw;
                      top: 2.656vw;
                    }
                    &:nth-child(5){
                      width: 9.53vw;
                      height: 0.625vw;
                      border-radius: 0.3125vw;
                      left: 5vw;
                      top: 3.593vw;
                    }
                    &:nth-child(6){
                      width: 10.859vw;
                      height: 0.625vw;
                      border-radius: 0.3125vw;
                      left: 5vw;
                      top: 4.53vw;
                    }
                    &:nth-child(7){
                      width: 100%;
                      height: 0.156vw;
                      left: 0;
                      top: 6.4vw;
                    }
                    &:nth-child(8){
                      width: 7.812vw;
                      height: 0.625vw;
                      border-radius: 0.3125vw;
                      left: 1.25vw;
                      top: 8.2vw;
                    }
                    &:nth-child(9){
                      width: 5.468vw;
                      height: 1.4vw;
                      border-radius: 0.3125vw;
                      left: 12.03vw;
                      top: 7.812vw;
                    }
                  }
                }
              }
              &.product2 {
                &:hover{
                  .product-image{
                    &:after{
                      transform: scale(1.1);
                      opacity: 0.7;
                    }
                    &:before{
                      transform: scale(1.15);
                      opacity: 1;
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  background: url(../img/product2_image.png) no-repeat 50% 50%;
                  background-size: 100% auto;
                  top: 0;
                  &:after{
                    position: absolute;
                    display: block;
                    width: 6vw;
                    height: 6.95vw;
                    left: calc(50% - 2.65vw);
                    top: 3.1vw;
                    content: "";
                    background: url(../img/product2_rama1.svg) no-repeat 50% 50%;
                    background-size: contain;
                    transition: all 300ms ease-out;
                    transform: scale(1);
                  }
                  &:before{
                    position: absolute;
                    display: block;
                    width: 7.52vw;
                    height: 8.59vw;
                    left: calc(50% - 3.4vw);
                    top: 2.19vw;
                    content: "";
                    background: url(../img/product2_rama2.svg) no-repeat 50% 50%;
                    background-size: contain;
                    opacity: 0.3;
                    transition: all 300ms ease-out;
                    transform: scale(1);
                  }
                }
              }
              &.product3 {
                &:hover{
                  .product-image {
                    .product-image-block {
                      top: 2.18vw;

                      &:nth-child(2) {
                        left: 12.5vw;
                        top: 3.98vw;
                      }

                      &:nth-child(3) {
                        top: 10.7vw;
                      }

                      &:nth-child(4) {
                        top: 6.95vw;
                        left: 3.98vw;
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  top: 0;
                  .product-image-block{
                    position: absolute;
                    display: block;
                    width: 6.25vw;
                    height: 3.85vw;
                    left: 24px;
                    top: 3.9vw;
                    text-align: center;
                    text-transform: uppercase;
                    background: #E6EAEE;
                    color: #fff;
                    font-size: 1.05vw;
                    line-height: 3.85vw;
                    border-radius: 0.86vw;
                    transition: all 300ms ease-out;
                    &:nth-child(2){
                      width: 7.65vw;
                      left: 13.36vw;
                      top: 2.1875vw;
                    }
                    &:nth-child(3){
                      width: 6.09vw;
                      left: 13.36vw;
                      top: 9.68vw;
                    }
                    &:nth-child(4){
                      width: 10.859vw;
                      left: 5.93vw;
                      top: 6.4vw;
                      height: 4.06vw;
                      line-height: 4.06vw;
                      background: #fff;
                      filter: drop-shadow(6.89256px 6.89256px 21.8264px rgba(218, 220, 224, 0.6));
                      span{
                        background: linear-gradient(95.33deg, #1B214A 0%, #516398 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        opacity: 0.3;
                      }
                    }
                  }
                }
              }
              &.product4 {
                &:hover{
                  .product-image{
                    .product-image-block{
                      img{
                        width: 47%;
                      }
                      .product-image-block-vn{
                        &:nth-child(2){
                          width: 3.515vw;
                        }
                        &:nth-child(3){
                          width: 5.85vw;
                        }
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  top:0;
                  .product-image-block{
                    position: absolute;
                    display: block;
                    background: #fff;
                    width: calc(100% - 48px);
                    left: 24px;
                    top: 2.578vw;
                    height: 5vw;
                    border-radius: 1.33vw;
                    box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                    &:nth-child(2){
                      top: 8.04vw;
                    }
                    .product-image-block-vn{
                      position: absolute;
                      display: block;
                      width: 5.46vw;
                      height: 0.937vw;
                      border-radius: 0.312vw;
                      background: linear-gradient(225deg, #F0F3F5 0%, #F0F1F2 100%);
                      top: 1.25vw;
                      left: 1.25vw;
                      transition: all 300ms ease-out;
                      &:nth-child(2){
                        width: 6.328vw;
                        height: 0.54vw;
                        top: 2.5vw;
                      }
                      &:nth-child(3){
                        width: 2.578vw;
                        height: 0.54vw;
                        top: 3.2vw;
                      }
                    }
                    img{
                      position: absolute;
                      display: block;
                      width: 43.33%;
                      opacity: 1;
                      top: 1.25vw;
                      right: 1.25vw;
                      left: auto;
                      height: auto;
                      transition: all 300ms ease-out;
                    }
                  }
                }
              }
              &.product5 {
                &:hover{
                  .product-image{
                    .product-image-block{
                      &:nth-child(1){
                        margin-left: -1.875vw;
                        margin-top: -1.875vw;
                      }
                      &:nth-child(3){
                        margin-left: 1.875vw;
                        margin-top: 1.875vw;
                      }
                    }
                    .product-image-block{
                      position: absolute;
                      display: block;
                      width: 7.8125vw;
                      height: 7.8125vw;
                      left: calc(50% - 3.9vw);
                      top: calc(50% - 3.9vw);
                      background: #fff;
                      box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                      border-radius: 1.328vw;
                      &:nth-child(1){
                        margin-left: -2.65vw;
                        margin-top: -2.65vw;
                      }
                      &:nth-child(3){
                        margin-left: 2.65vw;
                        margin-top: 2.65vw;
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  top: 0;
                  .product-image-block{
                    position: absolute;
                    display: block;
                    width: 7.8125vw;
                    height: 7.8125vw;
                    left: calc(50% - 3.9vw);
                    top: calc(50% - 3.9vw);
                    background: #fff;
                    box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                    border-radius: 1.328vw;
                    transition: all 300ms ease-out;
                    &:nth-child(1){
                      margin-left: -1.875vw;
                      margin-top: -1.875vw;
                    }
                    &:nth-child(3){
                      margin-left: 1.875vw;
                      margin-top: 1.875vw;
                    }
                  }
                }
              }
              &.product6 {
                &:hover{
                  .product-image{
                    .product-image-block{
                      width: 15.54vw;
                      left: calc(50% - 7.77vw);
                      height: 2.7vw;
                      .product-image-block-vn{
                        &:nth-child(3){
                          transform: scale(1.1);
                          top: 0.53vw;
                        }
                      }
                      &:nth-child(3){
                        opacity: 1;
                        transform: scale(1);
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  background: url(../img/product6_image.png) no-repeat 50% 50%;
                  background-size: 100% auto;
                  top: 0;
                  .product-image-block{
                    position: absolute;
                    display: block;
                    width: 16.48vw;
                    height: 2.864vw;
                    top: 2.89vw;
                    left: calc(50% - 8.24vw);
                    box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                    background: #fff;
                    border-radius: 0.625vw;
                    transition: all 300ms ease-out;
                    .product-image-block-vn{
                      position: absolute;
                      display: block;
                      border-radius: 1.7476vw;
                      background: #E6EAEE;
                      width: 4.136vw;
                      height: 0.783vw;
                      left: 0.8593vw;
                      top: 1.01vw;
                      &:nth-child(2){
                        left: 5.625vw;
                      }
                      &:nth-child(3){
                        width: 1.7476vw;
                        height: 1.7476vw;
                        right: 0.4vw;
                        left: auto;
                        top: 0.5468vw;
                        transition: all 300ms ease-out;
                      }
                    }
                    &:nth-child(2){
                      top: 6.39vw;
                      .product-image-block-vn{
                        opacity: 0.5;
                      }
                    }
                    &:nth-child(3){
                      position: absolute;
                      display: block;
                      width: 2.34vw;
                      height: 2.34vw;
                      border-radius: 2.34vw;
                      background: #E6EAEE;
                      top: 10.15vw;
                      left: calc(50% - 1.17vw);
                      opacity: 0;
                      transform: scale(0.7);
                      transition: all 300ms ease-out;
                    }
                  }
                }
              }
              &.product7 {
                &:hover{
                  .product-image{
                    .product-image-block{
                      top: 2.578vw;
                      &:nth-child(2){
                        top: 4.84vw;
                      }
                      &:nth-child(3){
                        width: 7.03vw;
                        height: 7.03vw;
                        left: calc(50% - 3.515vw);
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  top: 0;
                  .product-image-block{
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4.84vw;
                    height: 4.84vw;
                    left: 1.17vw;
                    top: 3.67vw;
                    background: #E6EAEE;
                    border-radius: 0.78vw;
                    transition: all 300ms ease-out;
                    img{
                      left: auto;
                      top: auto;
                      position: relative;
                      opacity: 1;
                      width: 56%;
                      height: auto;
                      transition: all 300ms ease-out;
                    }
                    &:nth-child(2){
                      right: 1.17vw;
                      left: auto;
                      top: 2.5vw;
                    }
                    &:nth-child(3){
                      width: 7.8125vw;
                      height: 7.8125vw;
                      left: calc(50% - 3.9vw);
                      top: 5.3125vw;
                      background: #fff;
                      box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.4);
                      border-radius: 1.328vw;
                    }
                  }
                }
              }
              &.product8 {
                &:hover{
                  .product-image{
                    .product-image-block{
                      &:nth-child(2){
                        transform: rotate(60deg);
                      }
                      &:nth-child(3){
                        width: calc(100% - 74px);
                        left: 37px;
                      }
                    }
                  }
                }
                .product-image{
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 15.625vw;
                  left: 0;
                  top: 0;
                  .product-image-block{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    img{
                      opacity: 1;
                      width: 100%;
                      height: 100%;
                    }
                    &:nth-child(1){
                    }
                    &:nth-child(2){
                      width: 2.34vw;
                      height: 2.34vw;
                      left: calc(50% - 1.17vw);
                      top: 3.36vw;
                      transition: all 300ms ease-out;
                    }
                    &:nth-child(3){
                      width: calc(100% - 48px);
                      top: 8.9vw;
                      height: 10.31vw;
                      overflow: hidden;
                      left: 24px;
                      transition: all 300ms linear;
                      background: url(../img/product8_image3.png) no-repeat 0 0;
                      background-size: 100% 100%;
                      border-radius: 0.625vw;
                    }
                  }
                }
              }


              .product-info{
                position: relative;
                display: block;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #5B6076;
                span{
                  position: relative;
                  display: block;
                  box-sizing: border-box;
                  padding-right: 4.61vw;
                }
                b{
                  position: relative;
                  display: block;
                  margin-bottom: 8px;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 120%;
                  letter-spacing: -0.04em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  @media screen and (max-width: 800px) {
                    font-size: 18px;
                  }
                }
              }
              img{
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                width: 100%;
                height: 380px;
                object-fit: contain;
                opacity: 0;
              }
              @media screen and (max-width: 800px) {
                padding-top: 200px;
                img{
                  opacity: 1;
                }
                .product-image{
                  display: none !important;
                }
              }

            }
          }
        }
      }
    }

    &#we-help{
      overflow: hidden;
      &.home-we-help{
        .container{
          margin-top: 0;
        }
      }
      .container{
        #we-help-list{
          position: relative;
          display: flex;
          justify-content: space-between;
          //padding-left: 55vw;
          @media screen and (max-width: 800px) {
            padding-left: 0;
            display: block;
          }
          img#we-help-image{
            position: absolute;
            display: block;
            width: 55vw;
            max-width: 900px;
            left: -40px;
            top: 0;
            @media screen and (max-width: 800px) {
              position: relative;
              left: 0;
              width: 343px;
              margin: 0 auto;
            }

          }
          video#we-help-video{
            align-self: start;
            background: #fff;
            display: block;
            height: 44vw;
            left: -40px;
            max-width: 900px;
            position: sticky;
            top: 0;
            width: 55vw;
            margin-bottom: -10vw;
            margin-top: -7vw;
            z-index: -1;
            @media screen and (max-width: 800px) {
              position: relative;
              left: 0;
              width: 343px;
              margin: 0 auto;
              height: 343px;
            }

          }
          .we-help-list-items{
            width: 45vw;
            @media screen and (max-width: 800px) {
              width: 100%;
            }

            .we-help-list-item{
              margin-bottom: 40px;
              font-weight: 500;
              font-size: 16px;
              line-height: 160%;
              font-feature-settings: 'pnum' on, 'lnum' on;
              color: #5B6076;
              .we-help-number{
                position: relative;
                display: block;
                width: 32px;
                height: 32px;
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                border-radius: 11px;
                font-weight: 600;
                font-size: 18px;
                line-height: 36px;
                text-align: center;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #FFFFFF;
                margin-bottom: 19px;
                @media screen and (max-width: 800px) {
                  border-radius: 8px;
                  font-size: 14px;
                  height: 24px;
                  line-height: 27px;
                  margin-bottom: 16px;
                  width: 24px;
                }

              }
              b{
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 20px;
                line-height: 120%;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-bottom: 8px;
                @media screen and (max-width: 800px) {
                  font-size: 18px;
                }
              }
            }
            a{
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 25px;
              text-align: center;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              text-decoration: none;
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              @media screen and (max-width: 800px) {
                font-size: 16px;
              }
              img{
                position: relative;
                display: inline-block;
                vertical-align: middle;
                top: -1px;
              }
            }
          }
        }
      }
    }

    &#calculator{
      &.small-margin-bottom{
        .container {
          margin-bottom: 16px !important;
        }
      }

      @media screen and (max-width: 800px) {
        margin: 0;
      }
      .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 85px;
        background: #F5F6F7;
        border-radius: 40px;
        margin-top: 0;
        justify-content: center;
        @media screen and (max-width: 800px) {
          padding: 56px 16px;
          margin: 0;
          border-radius: 16px;
        }
        .section-title{
          margin-bottom: 40px;
          @media screen and (max-width: 800px) {
            white-space: nowrap;
          }
        }

        #calculator-bookmarks{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 16px;
          max-width: 800px;
          @media screen and (max-width: 800px) {
            justify-content: flex-start;
            gap: 8px;
          }
          .calculator-bookmark{
            position: relative;
            display: block;
            padding: 0 24px;
            height: 48px;
            background: #FFFFFF;
            border-radius: 16px;
            line-height: 48px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            cursor: pointer;
            @media screen and (max-width: 800px) {
              padding: 0 18px;
              font-size: 14px;
            }
            &:hover{
              background: #ccc;
            }
            &.active{
              background: #4E5369;
              color: #fff;
            }
          }
        }

        #calculator-form{
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 24px;
          background: #FFFFFF;
          box-shadow: 0px 4px 32px rgba(223, 229, 235, 0.32), 0px 4px 40px rgba(223, 229, 235, 0.32);
          border-radius: 16px;
          margin-top: 40px;
          min-height: 320px;
          @media screen and (max-width: 800px) {
            margin-top: 26px;
          }

          .calculator-form-block{
            display: none;
            &.active{
              display: block;
            }
          }

          .calculator-right{
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            width: 382px;
            border-left: 1px solid #EBEBEB;
            height: 100%;
            @media screen and (max-width: 800px) {
              position: relative;
              display: block;
              width: 100%;
              border-left:0;
              border-top: 1px solid #EBEBEB;
            }
            small{
              position: relative;
              display: block;
              width: 100%;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              color: #B3B8BF;
              margin-bottom: 10px;
              @media screen and (max-width: 800px) {
                font-size: 12px;
                line-height: 16px;
              }
            }
            span{
              position: relative;
              display: block;
              font-weight: 600;
              font-size: 20px;
              line-height: 36px;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              @media screen and (max-width: 800px) {
                font-size: 18px;
                line-height: 36px;
              }
            }
            .calculator-right-header{
              position: relative;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              border-bottom: 1px solid #EBEBEB;
              height: auto;
              padding: 24px;
              padding-bottom: 18px;
              box-sizing: border-box;
              @media screen and (max-width: 800px) {
                padding: 24px 16px 15px;
              }
            }
            .calculator-right-content{
              position: relative;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              height: auto;
              padding: 24px;
              box-sizing: border-box;
              @media screen and (max-width: 800px) {
                padding: 24px 16px;
              }
              button{
                box-sizing: border-box;
                outline: none;
                border: 0;
                width: 100%;
                height: 64px;
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                backdrop-filter: blur(95px);
                -webkit-backdrop-filter: blur(95px);
                border-radius: 16px;
                color: #fff;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                cursor: pointer;
                margin-top: 16px;
                margin-bottom: 16px;
                font-family: 'TT Wellingtons';

                @media screen and (max-width: 800px) {
                  height: 56px;
                  margin-top: 24px;
                  font-size: 16px;
                }
              }


            }
            .calculator-right-tumbler{
              position: relative;
              display: flex;
              justify-content: space-between;
              padding: 2px;
              box-sizing: border-box;
              width: 166px;
              height: 36px;
              background: #EBEDEF;
              border-radius: 10px;
              cursor: pointer;
              &.month{
                .calculator-right-tumbler-select{
                  left: 50%;
                }
              }
              span{
                position: relative;
                text-align: center;
                width: 50%;
                font-weight: 500;
                font-size: 14px;
                line-height: 33px;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #5B6076;
                @media screen and (max-width: 800px) {
                  font-size: 12px;
                }
              }
              .calculator-right-tumbler-select{
                position: absolute;
                display: block;
                background: #fff;
                border-radius: 8px;
                width: calc(50% - 2px);
                left: 2px;
                top: 2px;
                height: 32px;
                transition: all 200ms ease-in-out;
              }
            }
          }

          .calculator-left{
            *{
              user-select: none;
            }
            padding: 24px;
            width: calc(100% - 431px);
            @media screen and (max-width: 800px) {
              width: auto;
              padding: 24px 16px;
            }
            .calculator-title{
              font-weight: 500;
              font-size: 32px;
              line-height: 140%;
              letter-spacing: -0.04em;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-bottom: 24px;
              @media screen and (max-width: 800px) {
                font-size: 24px;
                line-height: 24px;
              }
            }
            .calculator-left-header{
              position: relative;
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              color: #B3B8BF;
              margin-bottom: 16px;
              @media screen and (max-width: 800px) {
                font-size: 12px;
                line-height: 14px;
              }
              span{
                position: relative;
                display: block;
                width: 121px;
                @media screen and (max-width: 800px) {
                  &:last-child{
                    display: none;
                  }
                }
                &:first-child{
                  position: relative;
                  display: block;
                  width: calc(100% - 242px - 132px);
                }
              }
            }
            .calculator-left-items{
              position: relative;
              display: flex;
              flex-direction: column;
              gap: 16px;
              .calculator-left-item{
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 56px;
                @media screen and (max-width: 800px) {
                  margin-bottom: 26px;
                  gap: 0;
                }
                .calculator-left-item-price{
                  position: relative;
                  display: block;
                  width: 121px;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 120%;
                  letter-spacing: -0.04em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  color: #4e556b;
                  span{
                    display: none;
                  }
                  @media screen and (max-width: 800px) {
                    position: absolute;
                    right: 0;
                    width: 100%;
                    font-size: 18px;
                    line-height: 120%;
                    top: 56px;
                    text-align: right;
                    white-space: nowrap;
                    span{
                      position: relative;
                      display: inline-block;
                      vertical-align: middle;
                      content: 'Цена в месяц';
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 140%;
                      color: #B3B8BF;
                      margin-right: 12px;
                    }
                  }
                }
                b{
                  position: relative;
                  display: block;
                  width: calc(100% - 242px - 132px);
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 120%;
                  letter-spacing: -0.04em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                  @media screen and (max-width: 800px) {
                    font-size: 18px;
                    line-height: 22px;
                    width: auto;
                    max-width: calc(100% - 121px);
                  }
                }
                .calculator-left-item-counter{
                  position: relative;
                  display: flex;
                  justify-content: space-between;
                  width: 121px;
                  height: 44px;
                  border: 1px solid #EBEBEB;
                  border-radius: 8px;
                  box-sizing: border-box;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 44px;
                  letter-spacing: -0.01em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  overflow: hidden;
                  input{
                    position: absolute;
                    width: 121px;
                    height: 44px;
                    text-align: center;
                    line-height: 44px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #5b6076;
                    font-family: TT Wellingtons;
                    border: 0;
                    outline: none;
                    display: block;
                    &[type=checkbox]{
                      display: none;
                    }
                  }
                  &.disabled{
                    pointer-events: none;
                    .calculator-minus{
                      opacity: 0.3;
                    }
                    .calculator-plus{
                      opacity: 0.3;
                    }
                  }
                  //background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                  //-webkit-background-clip: text;
                  //-webkit-text-fill-color: transparent;
                  //background-clip: text;
                  //text-fill-color: transparent;
                  .calculator-minus{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 44px;
                    height: 44px;
                    cursor: pointer;
                    z-index: 1;
                  }
                  .calculator-plus{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 44px;
                    height: 44px;
                    cursor: pointer;
                    z-index: 1;
                  }
                  //input{
                  //  display: none;
                  //}
                  &.toggle{
                    border: 0;
                  }
                  .toggle-element{
                    position: absolute;
                    display: block;
                    right: 0;
                    background: rgba(120, 120, 128, 0.16);
                    width: 51px;
                    height: 31px;
                    border-radius: 16px;
                    top: 6px;
                    cursor: pointer;
                    transition: all 200ms linear;
                    &:after{
                      position: absolute;
                      display: block;
                      width: 27px;
                      height: 27px;
                      border-radius: 15px;
                      content: '';
                      left: 2px;
                      top: 2px;
                      background: #FFFFFF;
                      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                      transition: all 200ms ease-out;
                    }
                    &.active{
                      background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                      &:after{
                        left: calc(100% - 29px);
                      }
                    }
                  }
                  .toggle-element-kvm{
                    position: absolute;
                    display: block;
                    right: 0;
                    background: rgba(120, 120, 128, 0.16);
                    width: 51px;
                    height: 31px;
                    border-radius: 16px;
                    top: 6px;
                    cursor: pointer;
                    transition: all 200ms linear;
                    &:after{
                      position: absolute;
                      display: block;
                      width: 27px;
                      height: 27px;
                      border-radius: 15px;
                      content: '';
                      left: 2px;
                      top: 2px;
                      background: #FFFFFF;
                      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
                      transition: all 200ms ease-out;
                    }
                    &.active{
                      background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                      &:after{
                        left: calc(100% - 29px);
                      }
                    }
                  }
                }
              }
            }
            .calculator-left-bookmarks{
              display: flex;
              gap: 32px;
              margin-bottom: 32px;
              span{
                position: relative;
                display: block;
                font-weight: 600;
                font-size: 20px;
                line-height: 120%;
                text-align: center;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                padding-bottom: 16px;
                opacity: 0.5;
                cursor: pointer;
                &.active{
                  opacity: 1;
                  &:after{
                    position: absolute;
                    display: block;
                    top: 100%;
                    height: 3px;
                    width: 100%;
                    content: "";
                    background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  }
                }
              }
            }
            .calculator-left-bookmarks-contents{
              .calculator-left-items{
                display: none;
                &.active{
                  display: flex;
                }
              }
            }
          }
        }
      }
    }

    &#features{
      &.small-margin-top{
        .container {
          margin-top: 16px !important;
          @media screen and (max-width: 800px) {
            margin-top: 0 !important;
          }
        }
      }
      &.home-features{
        .container{
          margin-top: 0;
        }
      }

      &.about-features{
        margin-top: -607px;
        @media screen and (max-width: 800px) {
          margin: 0 0;
          padding-top: 0;
          height: auto;
        }
        .section-title{
          font-size: 32px;
          line-height: 140%;
          margin-top: 80px;
          @media screen and (max-width: 800px) {
            font-size: 18px !important;
            line-height: 120% !important;
            text-align: left;
          }
        }
        .container{
          @media screen and (max-width: 800px) {
            padding: 56px 24px;
          }
          .about-cloud1{
            position: absolute;
            display: block;
            left: 0;
            top: 142px;
            img{
              width: 142px;
            }
            @media screen and (max-width: 800px) {
              left: -30px;
              top: 92px;
            }
          }
          .about-cloud2{
            position: absolute;
            display: block;
            left: calc(50% - 200px);
            bottom: 0;
            img{
              width: 375px;
            }
          }
          #features-slider .features-slider-item .features-slider-item-content img{
            top: -54px;
            position: relative;
            @media screen and (max-width: 800px) {
              top:0;
            }
          }
          .features-slider-item-content{
            margin-left: -43px;
          }
          @media screen and (max-width: 800px) {
            padding-top: 0;
            margin-top: -16px !important;
            #features-slider{
              height: auto;
              margin-bottom: 0 !important;
              .features-slider-item-content{
                margin-left: 0px;
              }
              .features-slider-item-data{
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }

      .container{
        background: url(../img/features.jpg) no-repeat 50% 50%;
        background-size: cover;
        border-radius: 40px;
        min-height: 400px;
        overflow: hidden;
        margin-bottom: 16px;
        @media screen and (max-width: 800px) {
          margin: 0;
          border-radius: 16px;
          padding: 56px 16px;
        }

        small{
          position: relative;
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 130.02%;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: #636979;
          padding-top: 80px;
          margin-bottom: 24px;
          @media screen and (max-width: 800px) {
            padding-top: 0;
          }
        }
        .section-title{
          background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 32px;
            padding: 0;
            br{
              content: ' ';
              display: inline-block;
              width: 6px;
            }
          }
        }

        #features-slider-dots{
          display: flex;
          justify-content: space-between;
          width: 300px;
          margin: 0 auto;
          gap: 8px;
          padding-bottom: 96px;
          &.active{
            .swiper-pagination-bullet{
              &.swiper-pagination-bullet-active{
                &:before{
                  animation: progressSlider 5s linear forwards;
                }
              }
            }
          }

          @media screen and (max-width: 800px) {
            padding-bottom: 0;
            padding-top: 50px;
          }
          .swiper-pagination-bullet{
            position: relative;
            display: block;
            width: 100%;
            height: 4px;
            background: #636979;
            border-radius: 16px;
            //overflow: hidden;
            &:after{
              position: absolute;
              width: 100%;
              height: 30px;
              top: -15px;
              content: "";
              display: block;
              left: 0;
              cursor: pointer;
            }
            &.swiper-pagination-bullet-active{
              &:before{
                position: absolute;
                display: block;
                content: "";
                width: 0%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 2px;
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
              }
            }
          }
        }
        #features-slider{
          position: relative;
          display: block;
          white-space: nowrap;
          width: 100%;
          height: 400px;
          margin-bottom: 40px;
          font-size: 0;
          .features-slider-item{
            position: relative;
            display: inline-block;
            white-space: normal;
            width: 100%;
            @media screen and (max-width: 800px) {
              margin-right: 16px;
              &:last-child{
                margin-right: 0;
              }
            }
            .features-slider-item-content{
              position: relative;
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              @media screen and (max-width: 800px) {
                flex-direction: column;
              }
              img{
                height: 400px;
                @media screen and (max-width: 800px) {
                  height: 247px;
                }
              }
              .features-slider-item-data{
                width: 390px;
                margin-left: -15%;
                margin-bottom: 40px;
                font-weight: 500;
                font-size: 16px;
                line-height: 160%;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #B8C0CF;
                img{
                  position: relative;
                  display: block;
                  width: 32px;
                  height: 32px;
                  margin-bottom: 19px;
                  top: 0;
                }
                @media screen and (max-width: 800px) {
                  margin-left: 0;
                  width: auto;
                }
                a{
                  position: relative;
                  display: inline-block;
                  line-height: 64px;
                  text-decoration: none;
                  padding: 0 32px;
                  //background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.15) 0%, rgba(243, 243, 243, 0.12) 100%);
                  //backdrop-filter: blur(95px);
                  //-webkit-backdrop-filter: blur(95px);
                  background: #3E485B;
                  border-radius: 16px;
                  color: #fff;
                  margin-top: 16px;
                }

                .features-slider-item-number{
                  position: relative;
                  display: block;
                  width: 32px;
                  height: 32px;
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  border-radius: 11px;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 36px;
                  text-align: center;
                  letter-spacing: -0.02em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  color: #FFFFFF;
                  margin-bottom: 19px;
                  @media screen and (max-width: 800px) {
                    border-radius: 8px;
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 26px;
                    margin-bottom: 16px;
                  }
                }
                b{
                  position: relative;
                  display: block;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 120%;
                  letter-spacing: -0.04em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  color: #FFFFFF;
                  margin-bottom: 8px;
                  @media screen and (max-width: 800px) {
                    font-size: 18px;
                  }
                }

              }
            }

          }
        }
      }
    }

    @keyframes animateLine {
      to {
        transform: translateX(-100%);
      }
    }
    &#logos{
      width: 100%;
      overflow: hidden;
      height: 80px;
      //margin-bottom: 120px;
      font-size: 0;
      white-space: nowrap;
      @media screen and (max-width: 800px) {
        height: 50px;
        //margin-bottom: 80px;
      }
      .logos-line{
        position: relative;
        display: block;
        height: 100%;
        white-space: nowrap;
        .logos-list{
          position: relative;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          white-space: nowrap;
          transform: translateX(0%);
          &.animate{
            animation: animateLine 50s infinite linear;
          }
          .logo-item{
            position: relative;
            display: inline-block;
            vertical-align: top;
            margin-right: 16px;
            //transform: translateX(-100vw);
            @media screen and (max-width: 800px) {
              margin-right: 8px;
              img{
                transform: scale(0.8);
              }
            }
            .logo-item-content{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 28px;
              height: 80px;
              border: 1px solid #EDEFF3;
              border-radius: 16px;
              box-sizing: border-box;
              @media screen and (max-width: 800px) {
                height: 50px;
                padding: 0 16px;
              }
            }
          }
        }

      }
    }

    &.policy-page{
      background: #FFFFFF;
      padding: 120px 0;
      .container{
        width: calc(100% - 40px);
        max-width: 794px;
        margin: 0 auto;

        ul{
          list-style: none;
          margin: 32px 0 48px;
          padding-left: 48px;
          font-feature-settings: 'pnum' on, 'lnum' on;
          li{
            margin-bottom: 24px;
            max-width: 698px;
          }
          @media screen and (max-width: 800px) {
            padding-left: 0;
          }


        }
      }
    }

    &.page404{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      height: 529px;
      background: url(../img/404bg.png) no-repeat 50% 50%;
      background-size: cover;

      .container{
        margin: 0;
        padding: 0;
        text-align: center;
        .section-title{
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 12px;
          }

        }
        @media screen and (max-width: 800px) {
          br{
            display: inline-block;
            content: "";
            width: 4px;
          }
        }

        a{
          align-items: center;
          background: linear-gradient(92.45deg,#f60,#ff9400);
          border-radius: 16px;
          color: #fff;
          display: flex;
          font-size: 18px;
          height: 64px;
          justify-content: center;
          letter-spacing: -.02em;
          line-height: 25px;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: 168px;
          margin: 32px auto 0;
          @media screen and (max-width: 800px) {
            height: 56px;
            line-height: 56px;
          }

        }
      }
    }

    &.about-top{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      padding-bottom: 607px;
      height: 621px;
      background: url(../img/about_top_bg.jpg) no-repeat 50% 50%;
      background-size: cover;

      .container{
        margin: 0;
        padding: 0;
        text-align: center;
        .section-title{
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 12px;
            padding-left: 0;
          }

        }
      }
      @media screen and (max-width: 800px) {
        justify-content: flex-start;
        align-items: flex-start;
        height: 507px;
        padding-bottom: 0;
        background: url(../img/about_top_bg_m.png) no-repeat 50% 50%;
        background-size: cover;
        .container{
          margin: 0 16px;
          .section-title{
            margin-top: 90px;
            font-size: 52px;
            line-height: 52px;
          }
        }
      }
    }

    &.about-dark-block{
      margin: -607px 0 0;
      @media screen and (max-width: 800px) {
        margin: 0 0;
      }
      .container{
        position: relative;
        display: block;
        min-height: 400px;
        background: radial-gradient(50% 50% at 50% 50%, #313F59 0%, #0F192E 100%);
        border-radius: 40px;
      }
    }

    &.blog-top{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      height: 529px;
      background: url(../img/blog_bg.png) no-repeat 50% 50%;
      background-size: cover;

      .container{
        margin: 0;
        padding: 0;
        text-align: center;
        .section-title{
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 52px;
            line-height: 52px;
            margin-bottom: 12px;
            padding-left: 0;
          }

        }
      }
      @media screen and (max-width: 800px) {
        justify-content: flex-start;
        align-items: flex-start;
        height: 507px;
        background: url(../img/blog_bg_m.png) no-repeat 50% 50%;
        background-size: cover;
        .container{
          margin: 0 16px;
          .section-title{
            margin-top: 90px;
          }
        }
      }
    }

    &.blog-filter{
      margin: 48px 0;
      @media screen and (max-width: 800px) {
        margin-top: 80px;
      }

      .container{
        position: relative;
        margin: 0 40px;
        .blog-filter-bookmarks{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          .blog-serach-form{
            position: relative;
            display: block;
            width: 100%;
            max-width: 389px;
            height: 64px;
            input{
              position: absolute;
              display: block;
              outline: none;
              width: 100%;
              height: 64px;
              line-height: 64px;
              padding: 0 16px;
              font-feature-settings: 'pnum' on, 'lnum' on;
              color: #7a7f8f;
              border: 1px solid #EBEBEB;
              border-radius: 16px;
              font-size: 16px;
              &:hover{
                border: 1.5px solid #B3B8BF;
              }
              &:focus{
                border: 1px solid #5B6076;
              }
            }
            img{
              position: absolute;
              display: block;
              cursor: pointer;
              right: 20px;
              top: 20px;
            }
          }
          .blog-filter-bookmarks-links{
            display: flex;
            gap: 32px;
            a{
              position: relative;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              text-align: center;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              padding-bottom: 16px;
              opacity: 0.5;
              text-decoration: none;
              &:hover{
                opacity:1;
              }
              &.active{
                opacity:1;
                &:after{
                  position: absolute;
                  display: block;
                  content: "";
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  height: 3px;
                }
              }
            }
          }
        }

        @media screen and (max-width: 800px) {
          margin: 0 16px;
          .blog-filter-bookmarks{
            flex-direction: column-reverse;
            .blog-serach-form{
              height: 56px;
              margin-bottom: 40px;
              input{
                height: 56px;
                line-height: 56px;
              }
              img{
                right: 16px;
                top: 16px;
              }
            }
            .blog-filter-bookmarks-links{
              a{
                font-size: 16px;
                padding-bottom: 8px;
              }
            }
          }
        }

      }
    }

    &.blog-lists{
      margin: 48px 0;
      overflow: visible;
      .blog-list{
        display: none;
        &:nth-child(1){
          display: block;
        }

        .blog-list-filter{
          position: relative;
          display: flex;
          gap: 16px;
          margin: -20px 40px 48px;
          &.margin-top0{
            margin-top: 0;
          }
          .blog-list-filter-select{
            position: relative;
            display: block;
            width: 288px;
            height: 64px;
            padding: 0 16px;
            box-sizing: border-box;
            border: 1.5px solid #EBEBEB;
            border-radius: 16px;
            line-height: 62px;
            &:hover{
              border: 1.5px solid #5B6076;
            }

            .select-title{
              width: 100%;
              height: 62px;
              color: #5B6076;
              opacity: 0.8;
              cursor: pointer;
              &:after{
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
                right: 20px;
                top: 20px;
                content: "";
                background: url(../img/blog_select.svg) no-repeat 50% 50%;
              }
            }

            &.active{
              .select-title{
                &:after{
                  transform: rotate(180deg);
                }
              }
              border: 1.5px solid #5B6076;
              .select-items{
                position: absolute;
                left: 0;
                z-index: 10;
                top: 58px;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                gap: 8px;
                flex-direction: column;
                padding: 24px;
                background: #FFFFFF;
                box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
                border-radius: 16px;
                .select-item{
                  position: relative;
                  display: block;
                  line-height: 24px;
                  font-weight: 500;
                  font-size: 16px;
                  cursor: pointer;
                  &:hover{
                    color: #0F192E;
                  }
                }
                .select-item.checkbox{
                  position: relative;
                  display: block;
                  line-height: 24px;
                  font-weight: 500;
                  font-size: 16px;
                  cursor: pointer;
                  padding-left: 28px;
                  &:before{
                    position: absolute;
                    display: block;
                    content: "";
                    left: 0;
                    top: 2px;
                    width: 16px;
                    height: 16px;
                    background: #F5F6F7;
                    border: 1.5px solid #EBEBEB;
                    border-radius: 4px;
                    box-sizing: border-box;
                  }
                  &.active{
                    &:before {
                      background: url(../img/select_check.svg) no-repeat 50% 50%;
                      border: 0;
                    }
                  }
                  &:hover{
                    color: #0F192E;
                  }
                }

              }
            }

            .select-items{
              display: none;
            }
          }

          @media screen and (max-width: 800px) {
            margin: 0 16px 48px;
            display: block;
            .blog-list-filter-select{
              width: 100%;
              margin-bottom: 16px;
              height: 56px;
              .select-title{
                height: 54px;
                line-height: 54px;
                &:after{
                  right: 16px;
                  top: 16px;
                }
              }
              .select-items {
                top: 50px !important;
              }
            }
          }

        }
      }
      .knowledge-base{
        .container{
          display: block;
          margin-bottom: 120px;
          .knowledge-base-items{
            position: relative;
            display: flex;
            gap: 16px;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 56px;
            .empty-block{
              width: calc(33.333% - 12px);
            }
            a{
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              box-sizing: border-box;
              padding: 40px 24px;
              border: 1px solid #F5F6F7;
              border-radius: 16px;
              color: #5B6076;
              width: calc(33.333% - 12px);
              background: #F5F6F7;
              overflow: hidden;
              @media screen and (max-width: 800px) {
                width: 100%;
                font-size: 18px;
                line-height: 24px;
              }

              &:hover{
                border: 1px solid #EBEBEB;
                background: #fff;
              }
              &.more-link{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border: 1px solid #EBEBEB;
                background: #fff;
                span{
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 140%;
                  text-align: center;
                  letter-spacing: -0.02em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                }
                &:hover{
                  span{
                    border-bottom: none;
                  }
                }
              }
              span{
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                box-sizing: border-box;
                border-bottom: #F5F6F7 solid 1px;
              }
              &:hover{
                span{
                  border-bottom: #121630 solid 1px;
                }
              }
            }
          }
        }
      }

      .container{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 40px;
        margin-bottom: 56px;
        @media screen and (max-width: 800px) {
          margin-bottom: 40px !important;
        }
        &.about-blog-list{
          .swiper-wrapper{
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;

            @media screen and (max-width: 800px) {
              width: 1680px;
              gap:0;
              .blog-list-item{
                display: block;
                white-space: normal;
                width: 320px;
              }
            }

          }
        }
        .blog-list-pagination{
          position: relative;
          display: none;
          @media screen and (max-width: 800px) {
            display: block;
          }
          overflow: hidden;
          margin: 0 auto;
          margin-top: 40px;
          width: 192px;
          height: 4px;
          background: #EBEBEB;
          border-radius: 2px;
          .swiper-pagination-progressbar-fill{
            background: #b3b8bf;
            width: 100%;
            position: absolute;
            left: 0;
            height: 4px;
            display: block;
            border-radius: 2px;
            transform-origin: left top;
          }
        }
        .section-title{
          text-align: center;
          margin: 0 auto;
          margin-bottom: 56px;
          @media screen and (max-width: 800px) {
            margin-bottom: 40px;
          }


        }
        .blog-list-item-empty{
          width: calc(33.333% - 12px);
        }
        .blog-list-item{
          position: relative;
          display: block;
          text-decoration: none;
          box-sizing: border-box;
          padding: 24px;
          border: 1px solid #F5F6F7;
          border-radius: 16px;
          color: #5B6076;
          width: calc(33.333% - 12px);
          background: #F5F6F7;
          min-height: 460px;
          overflow: hidden;
          &:hover{
            border: 1px solid #EBEBEB;
            background: #fff;
            .blog-list-item-header{
              span{
                background: #F5F6F7;
              }
            }
            .blog-link{
              visibility: visible;
            }
          }
          img.blog-list-item-image{
            width: calc(100% + 48px);
            height: 240px;
            object-fit: cover;
            margin-top: -24px;
            margin-left: -24px;
            margin-bottom: 24px;
          }
          &.big-blog-item{
            width: calc(66.66% - 8px);
            .big-blog-item-over{
              position: absolute;
              display: block;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, #192236 -100.54%, rgba(18, 23, 33, 0) 162.5%);
            }
            .blog-list-item-header{
              color: #fff;
              span{
                background: linear-gradient(94.71deg, rgba(243, 243, 243, 0.15) 0%, rgba(243, 243, 243, 0.12));
              }
            }
            .blog-link{
              display: none;
            }
            strong{
              position: absolute;
              display: block;
              bottom: 24px;
              left: 24px;
              width: calc(100% - 48px);
              max-width: 640px;
              color: #fff;
              background: none;
              -webkit-background-clip: initial;
              -webkit-text-fill-color: white;
              background-clip: initial;
              text-fill-color: white;
              margin-bottom: 0;
            }
          }
          .blog-list-item-header{
            position: relative;
            display: flex;
            gap: 16px;
            font-weight: 500;
            font-size: 14px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            line-height: 48px;
            color: #5B6076;
            margin-bottom: 24px;
            span{
              position: relative;
              display: block;
              background: #ffffff;
              font-size: 16px;
              border-radius: 16px;
              padding: 0 24px;
            }
          }
          strong{
            position: relative;
            display: block;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
          &.with-image{
            strong{
              margin-bottom: 0;
            }
          }
          .blog-link{
            position: relative;
            display: inline-block;
            margin-top: 25px;
            visibility: hidden;
            span{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              text-align: center;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            img{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: -2px;
            }
          }
        }

        @media screen and (max-width: 800px) {
          display: block;
          margin: 0 16px;
          margin-bottom: 56px;
          .blog-list-item{
            width: 100%;
            margin-bottom: 16px;
            min-height: 0;
            .blog-link{
              visibility: visible;
            }
            &.big-blog-item{
              width: 100%;
              strong{
                position: relative;
                display: block;
                bottom: auto;
                left: 0;
                margin-right: 0;
                width: auto;
                font-size: 18px;
              }
              .big-blog-item-over{
                background: linear-gradient(107.97deg, #14223F 16.79%, rgba(18, 23, 33, 0) 210.58%);
              }
              .blog-link{
                display: block;
              }
            }
            .blog-list-item-header{
              font-size: 12px;
              span{
                font-size: 16px;
              }
            }
            img.blog-list-item-image{
              height: 200px;
            }
          }
        }


      }

      .link-to-blog{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 10px;
        width: 240px;
        height: 64px;
        background: #EEF0F1;
        border-radius: 16px;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        box-sizing: border-box;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #0F192E;
        margin: 0 auto 120px;
        @media screen and (max-width: 800px) {
          box-sizing: border-box;
          width: calc(100% - 32px);
          height: 56px;
          line-height: 56px;
          margin: 0 auto 80px;
          font-size: 16px;
        }

      }
      .blog-list-pagination{
        position: relative;
        display: flex;
        margin: 0 40px 120px;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
          margin: 0 16px 48px;
          flex-direction: column;
        }
        a{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 64px;
          width: 64px;
          box-sizing: border-box;
          border: 1px solid #DFDFE0;
          border-radius: 16px;
          font-size: 18px;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0F192E;
          text-decoration: none;
          @media screen and (max-width: 800px) {
            font-size: 16px;
            height: 56px;
            line-height: 56px;
            width: 56px;
          }
          &.load-page{
            width: 240px;
            text-align: center;
            background: #F5F6F7;
            border:none;
            font-size: 18px;
            @media screen and (max-width: 800px) {
              margin-bottom: 40px;
              font-size: 16px;
              line-height: 56px;
              height: 56px;
              width: auto;
            }
          }
        }
        .blog-list-pages{
          display: flex;
          gap: 8px;
          @media screen and (max-width: 800px) {
            display: none;
          }
          a{
            &:hover{
              border-color: #FF6600;
            }
          }
        }
        .blog-list-pages-mobile{
          display: none;
          justify-content: center;
          gap: 8px;
          @media screen and (max-width: 800px) {
            display: flex;
          }
          a{
            &.active{
              border-color: #FF6600;
            }
            &:first-child{
              img{
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    &#post-page{
      .container{
        #back-btn{
          position: absolute;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          left: -20px;
          gap: 4px;
          top: 0;
          line-height: 48px;
          padding: 0 24px 0 18px;
          background: #F5F6F7;
          border-radius: 16px;
          font-weight: 500;
          font-size: 16px;
          white-space: nowrap;
          color: #5B6076;
          text-decoration: none;
        }
        .blog-content{
          position: relative;
          width: calc(100% - 40px);
          max-width: 794px;
          margin: 0 auto;
          .share-links{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 48px;
            gap: 24px;
            span{
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              text-align: center;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            div{
              display: flex;
              gap: 4px;
            }
          }
          .post-type-content{
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            margin-bottom: 32px;
            .post-type{
              position: relative;
              display: block;
              line-height: 48px;
              padding: 0 24px;
              background: #F5F6F7;
              border-radius: 16px;
              font-weight: 500;
              font-size: 16px;
              white-space: nowrap;
              color: #5B6076;
            }
            span{
              left: calc(100% + 16px);
              top: 0;
              color: #5B6076;
              text-align: center;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              font-weight: 600;
              font-size: 14px;
            }
          }
          img{
            max-width: 100%;
            border-radius: 16px;
            overflow: hidden;
            object-fit: cover;
          }
          h1{
            font-weight: 500;
            font-size: 32px;
            line-height: 140%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin: 32px 0;
          }
          h3{
            font-weight: 600;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-bottom: 48px;
          }
          h5{
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            margin: 0;
            margin-bottom: 4px;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }

        @media screen and (max-width: 1180px) {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          #back-btn{
            position: relative;
          }
          .blog-content{
            margin: 0;
          }
        }
        @media screen and (max-width: 800px) {
          display: flex;
          flex-direction: column;
          #back-btn{
            position: relative;
            left: 0;
            margin-bottom: 20px;
          }
          .blog-content{
            margin: 0;
            width: 100%;
            max-width: 800px;
          }
        }


      }
    }

    &.contacts-top{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      height: 529px;
      background: url(../img/contacts_bg.png) no-repeat 50% 50%;
      background-size: cover;

      .container{
        margin: 0;
        padding: 0;
        text-align: center;
        .section-title{
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 52px;
            line-height: 52px;
            margin-bottom: 12px;
            padding-left: 0;
          }

        }
      }
      @media screen and (max-width: 800px) {
        justify-content: flex-start;
        align-items: flex-start;
        height: 507px;
        background: url(../img/blog_bg_m.png) no-repeat 50% 50%;
        background-size: cover;
        .container{
          margin: 0 16px;
          .section-title{
            margin-top: 90px;
          }
        }
      }
    }

    &.contacts-content{
      .container{
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-top: 120px;
        margin-bottom: 56px;
        @media screen and (max-width: 800px) {
          margin-top: 80px;
          margin-bottom: 40px;
          flex-direction: column;
        }

        .contacts-block{
          position: relative;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 33.333%;
          background: #F5F6F7;
          border-radius: 24px;
          padding: 24px;
          box-sizing: border-box;
          @media screen and (max-width: 800px) {
            width: 100%;
          }
          .contacts-block-item{
            position: relative;
            display: block;
            &:first-child{
              margin-bottom: 32px;
            }
            @media screen and (max-width: 800px) {
              font-size: 16px;
              margin-bottom: 24px;
              &:first-child{
                margin-bottom: 24px;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
            strong{
              display: block;
              margin-bottom: 24px;
              font-weight: 600;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.04em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              @media screen and (max-width: 800px) {
                font-size: 18px;
                line-height: 21px;
              }
            }
            .contacts-block-icon-block{
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              font-feature-settings: 'pnum' on, 'lnum' on;
              a{
                font-weight: 500;
                font-size: 16px;
                line-height: 48px;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #5B6076;
                margin-bottom: 12px;
                text-decoration: none;
                white-space: nowrap;
                &.phone-link{
                  font-weight: 600;
                  font-size: 18px;
                }
                @media screen and (max-width: 800px) {
                  font-size: 16px;
                }
                img{
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 12px;
                }
              }
            }
          }
          &.big-contacts-block{
            width: 66.666%;
            @media screen and (max-width: 800px) {
              width: 100%;
            }
            .contacts-block-item{
              width: 50%;
              @media screen and (max-width: 800px) {
                width: 100%;
              }
              .contacts-block-icon-block{
                flex-direction: row;
                justify-content: flex-start;
                gap: 4px;
                @media screen and (max-width: 800px) {
                  flex-wrap: wrap;
                }
                a{
                  &:first-child{
                    margin-right: 20px;
                    width: auto;
                  }
                  &.social-link{
                    img{
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.contacts-map{
      .container{
        margin-bottom: 120px;
        margin-top: 0;
        @media screen and (max-width: 800px) {
          margin-bottom: 80px;
        }
        #map{
          background: #F2F2F2;
          border-radius: 16px;
          height: 628px;
          overflow: hidden;
        }
      }
    }

    &.vacancies-top{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
      height: 529px;
      background: url(../img/blog_bg.png) no-repeat 50% 50%;
      background-size: cover;

      .container{
        margin: 0;
        margin-top: -80px;
        padding: 0;
        text-align: center;
        a{
          position: relative;
          display: block;
          background: #FFFFFF;
          border-radius: 16px;
          width: 170px;
          height: 48px;
          font-weight: 600;
          font-size: 14px;
          line-height: 48px;
          text-align: center;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #5B6076;
          margin: 0 auto;
          text-decoration:none;
          margin-bottom: 61px;
          svg{
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
            top: -2px;
          }
          @media screen and (max-width: 800px) {
            margin-left: 0;
            margin-top: 90px;
            margin-bottom: 32px;
          }

          &:hover{
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            backdrop-filter: blur(47.5px);
            span{
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(95.33deg, #FFFFFF 0%, rgba(255, 255, 255, 0.64) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            svg{
              path{
                fill: #ffffff;
              }
              rect{
                stroke: #ffffff;
              }
            }
          }
        }
        .section-title{
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 88px;
          line-height: 90%;
          text-align: center;
          letter-spacing: -0.07em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          @media screen and (max-width: 800px) {
            font-size: 52px;
            line-height: 52px;
            padding-left: 0;
            margin-top: 0;
          }

        }
      }
      @media screen and (max-width: 800px) {
        justify-content: flex-start;
        align-items: flex-start;
        height: 507px;
        background: url(../img/blog_bg_m.png) no-repeat 50% 50%;
        background-size: cover;
        .container{
          margin: 0 16px;
          .section-title{
            margin-top: 90px;
            text-align: left;
          }
        }
        &.vacancies-inner-top{
          .container{
            .section-title{
              margin-top: 0;
            }
          }
        }
      }
    }

    &.vacancies-lists{
      margin: 48px 0;
      .vacancies-list{
        display: none;
        &:nth-child(1){
          display: block;
        }

        .vacancies-list-filter{
          position: relative;
          display: flex;
          gap: 16px;
          margin: -20px 40px 48px;
          &.margin-top0{
            margin-top: 0;
          }
          .vacancies-list-filter-select{
            position: relative;
            display: block;
            width: calc(33.333% - 12px);
            height: 64px;
            padding: 0 16px;
            box-sizing: border-box;
            border: 1.5px solid #EBEBEB;
            border-radius: 16px;
            line-height: 62px;
            &:hover{
              border: 1.5px solid #5B6076;
            }

            .select-title{
              width: 100%;
              height: 62px;
              color: #5B6076;
              opacity: 0.8;
              cursor: pointer;
              &:after{
                position: absolute;
                display: block;
                width: 24px;
                height: 24px;
                right: 20px;
                top: 20px;
                content: "";
                background: url(../img/blog_select.svg) no-repeat 50% 50%;
              }
            }

            &.active{
              border: 1.5px solid #5B6076;
              .select-title{
                &:after{
                  transform: rotate(180deg);
                }
              }
              .select-items{
                position: absolute;
                left: 0;
                z-index: 10;
                top: 58px;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                gap: 8px;
                flex-direction: column;
                padding: 24px;
                background: #FFFFFF;
                box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
                border-radius: 16px;
                .select-item{
                  position: relative;
                  display: block;
                  line-height: 24px;
                  font-weight: 500;
                  font-size: 16px;
                  cursor: pointer;
                  &:hover{
                    color: #0F192E;
                  }
                }
              }
            }

            .select-items{
              display: none;
            }
          }

          @media screen and (max-width: 800px) {
            margin: 0 16px 48px;
            display: block;
            .vacancies-list-filter-select{
              width: 100%;
              margin-bottom: 16px;
              height: 56px;
              .select-title{
                height: 54px;
                line-height: 54px;
                &:after{
                  right: 16px;
                  top: 16px;
                }
              }
              .select-items {
                top: 50px !important;
              }
            }
          }

        }
      }
      .knowledge-base{
        .container{
          display: block;
          margin-bottom: 120px;
          .knowledge-base-items{
            position: relative;
            display: flex;
            gap: 16px;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 56px;
            .empty-block{
              width: calc(33.333% - 12px);
            }
            a{
              position: relative;
              display: flex;
              align-items: center;
              text-decoration: none;
              box-sizing: border-box;
              padding: 40px 24px;
              border: 1px solid #F5F6F7;
              border-radius: 16px;
              color: #5B6076;
              width: calc(33.333% - 12px);
              background: #F5F6F7;
              overflow: hidden;
              @media screen and (max-width: 800px) {
                width: 100%;
                font-size: 18px;
                line-height: 24px;
              }

              &:hover{
                border: 1px solid #EBEBEB;
                background: #fff;
              }
              &.more-link{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border: 1px solid #EBEBEB;
                background: #fff;
                span{
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 140%;
                  text-align: center;
                  letter-spacing: -0.02em;
                  font-feature-settings: 'pnum' on, 'lnum' on;
                  background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                }
                &:hover{
                  span{
                    border-bottom: none;
                  }
                }
              }
              span{
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: -0.04em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                box-sizing: border-box;
                border-bottom: #F5F6F7 solid 1px;
              }
              &:hover{
                span{
                  border-bottom: #121630 solid 1px;
                }
              }
            }
          }
        }
      }

      .container{
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 40px;
        margin-bottom: 56px;
        .section-title{
          margin-bottom: 0;
          text-align: center;
          margin: 0 auto;
        }
        .vacancies-list-item-empty{
          width: calc(33.333% - 12px);
        }
        .vacancies-list-item{
          position: relative;
          display: flex;
          flex-direction: column;
          text-decoration: none;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 24px;
          border: 1px solid #F5F6F7;
          border-radius: 16px;
          color: #5B6076;
          width: calc(33.333% - 12px);
          background: #F5F6F7;
          overflow: hidden;

          .vacancy-item-footer-tag{
            position: relative;
            display: inline-block;
            padding: 0 24px;
            height: 48px;
            line-height: 48px;
            background: #FFFFFF;
            border-radius: 16px;
            color: #5B6076;
            font-weight: 500;
            margin-bottom: 8px;
            margin-right: 8px;
            @media screen and (max-width: 800px) {
              padding: 0 16px;
            }

          }
          .vacancy-item-footer{
            padding-top: 34px;
            margin-top: 8px;
          }
          &:hover{
            border: 1px solid #EBEBEB;
            background: #fff;
            .vacancies-list-item-header{
              span{
                background: #F5F6F7;
              }
            }
            .vacancies-link{
              display: block;
            }
            .vacancy-item-footer{
              padding-top: 0px;
            }
            .vacancy-item-footer-tag{
              background: #F5F6F7;
            }
          }
          .vacancies-list-item-header{
            position: relative;
            display: flex;
            gap: 16px;
            font-weight: 500;
            font-size: 16px;
            font-feature-settings: 'pnum' on, 'lnum' on;
            line-height: 48px;
            color: #5B6076;
            margin-bottom: 24px;
            span{
              position: relative;
              display: block;
              background: #ffffff;
              border-radius: 16px;
              padding: 0 24px;
            }
          }
          strong{
            position: relative;
            display: block;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            @media screen and (max-width: 800px) {
              font-size: 18px;
            }
          }
          .vacancies-link{
            position: relative;
            display: none;
            margin-top: 8px;
            span{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              text-align: center;
              letter-spacing: -0.02em;
              font-feature-settings: 'pnum' on, 'lnum' on;
              background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            img{
              position: relative;
              display: inline-block;
              vertical-align: middle;
              top: -2px;
            }
          }
        }

        @media screen and (max-width: 800px) {
          display: block;
          margin: 0 16px;
          margin-bottom: 56px;
          .vacancies-list-item{
            width: 100%;
            margin-bottom: 16px;
            min-height: 0;
            .vacancies-link{
              display: block;
            }
            .vacancy-item-footer{
              padding-top: 0;
            }
            .vacancies-list-item-header{
              font-size: 12px;
              span{
                font-size: 16px;
              }
            }
          }
        }


      }

      .link-to-vacancies{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 10px;
        width: 240px;
        height: 64px;
        background: #EEF0F1;
        border-radius: 16px;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #0F192E;
        margin: 0 auto 120px;
        @media screen and (max-width: 800px) {
          box-sizing: border-box;
          width: calc(100% - 32px);
          margin: 0 auto 80px;
        }

      }
      .vacancies-list-pagination{
        position: relative;
        display: flex;
        margin: 0 40px 120px;
        justify-content: space-between;
        @media screen and (max-width: 800px) {
          margin: 0 16px 48px;
          flex-direction: column;
        }
        a{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 64px;
          width: 64px;
          box-sizing: border-box;
          border: 1px solid #DFDFE0;
          border-radius: 16px;
          color: #0F192E;
          text-decoration: none;
          @media screen and (max-width: 800px) {
            height: 64px;
          }
          &.load-page{
            width: auto;
            padding: 0 38px;
            background: #F5F6F7;
            border:none;
            @media screen and (max-width: 800px) {
              margin-bottom: 40px;
            }
          }
        }
        .vacancies-list-pages{
          display: flex;
          gap: 8px;
          @media screen and (max-width: 800px) {
            display: none;
          }
          a{
            &:hover{
              border-color: #FF6600;
            }
          }
        }
        .vacancies-list-pages-mobile{
          display: none;
          justify-content: center;
          gap: 8px;
          @media screen and (max-width: 800px) {
            display: flex;
          }
          a{
            &.active{
              border-color: #FF6600;
            }
            &:first-child{
              img{
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    &.vacancy-content{
      .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (max-width: 800px) {
          display: block;
        }
        .vacancy-block{
          position: relative;
          padding: 24px;
          //padding-right: 42px;
          max-width: 489px;
          width: 100%;
          background: #F5F6F7;
          border-radius: 16px;
          box-sizing: border-box;

          strong{
            position: relative;
            display: block;
            margin-bottom: 24px;
            font-weight: 500;
            font-size: 32px;
            line-height: 140%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            @media screen and (max-width: 800px) {
              font-size: 24px;
            }
          }
          span{
            position: relative;
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 160%;
            font-feature-settings: 'pnum' on, 'lnum' on;
            color: #5B6076;
            margin-bottom: 6px;
          }
          .vacancy-block-tags{
            margin-top: 24px;
            margin-bottom: 24px;
            .vacancy-block-tag{
              position: relative;
              display: inline-block;
              padding: 0 24px;
              height: 48px;
              line-height: 48px;
              background: #FFFFFF;
              border-radius: 16px;
              color: #5B6076;
              font-weight: 500;
              margin-bottom: 8px;
              margin-right: 8px;
              @media screen and (max-width: 800px) {
                padding: 0 16px;
              }
            }
          }
          a{
            position: relative;
            display: block;
            box-sizing: border-box;
            text-decoration: none;
            text-align: center;
            line-height: 64px;
            outline: none;
            border: 0;
            width: 100%;
            height: 64px;
            background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
            backdrop-filter: blur(95px);
            -webkit-backdrop-filter: blur(95px);
            border-radius: 16px;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;
            margin-top: 16px;
            margin-bottom: 0;
            font-family: 'TT Wellingtons';

            @media screen and (max-width: 800px) {
              height: 56px;
              margin-top: 24px;
              font-size: 16px;
            }
          }

        }
        .vacancy-text{
          position: relative;
          display: block;
          width: calc(100% - 611px);
          @media screen and (max-width: 800px) {
            width: auto;
            margin-top: 40px;
          }

          h3{
            padding: 0;
            margin: 0;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 32px;
            line-height: 140%;
            letter-spacing: -0.04em;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            @media screen and (max-width: 800px) {
              font-size: 24px;
              line-height: 24px;
            }
          }
          ul{
            position: relative;
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
            margin-bottom: 40px;
            li{
              position: relative;
              display: block;
              padding-left: 25px;
              margin-bottom: 8px;
              &:before{
                content: "—";
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;
                background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }
          }
        }
      }
    }

    &.fly-logo-block{
      .container{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 596px;
        margin: 120px 0;
        @media screen and (max-width: 800px) {
          margin: 80px 16px;
          height: 210px;
          min-height: 0;
          display: block;
          .section-title{
            br{
              content:" ";
              display: inline-block;
              width: 6px;
            }
          }
        }

        .fly-logo-block{
          position: absolute;
          display: block;
          width: 1280px;
          top: 0;
          height: 594px;
          left: calc(50% - 640px);
          @media screen and (max-width: 800px) {
            height: auto;
            left: 0;
          }
          @media screen and (max-width: 800px) {
            top: 108px;
            width: 100%;
            .swiper-wrapper{
              position: relative;
              white-space: nowrap;
              .fly-logo-item{
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin-right: 16px;
                left: 0 !important;
                top: 0 !important;
                padding: 18px 24px;
                height: auto;
                width: auto !important;
                img{
                  height: 23px;
                }
              }
            }
          }
          .fly-pagination{
            position: relative;
            display: none;
            @media screen and (max-width: 800px) {
              display: block;
            }
            overflow: hidden;
            margin: 0 auto;
            margin-top: 40px;
            width: 192px;
            height: 4px;
            background: #EBEBEB;
            border-radius: 2px;
            .swiper-pagination-progressbar-fill{
              background: #b3b8bf;
              width: 100%;
              position: absolute;
              left: 0;
              height: 4px;
              display: block;
              border-radius: 2px;
              transform-origin: left top;
            }
          }

          .fly-logo-item{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(69, 89, 129, 0.06), 0px 56px 80px rgba(127, 127, 162, 0.07), 0px 36.2963px 46.8519px rgba(127, 127, 162, 0.0531481), 0px 21.5704px 25.4815px rgba(127, 127, 162, 0.0425185), 0px 11.2px 13px rgba(127, 127, 162, 0.035), 0px 4.56296px 6.51852px rgba(127, 127, 162, 0.0274815), 0px 1.03704px 3.14815px rgba(127, 127, 162, 0.0168519);
            border-radius: 16px;
            transition: 500ms ease-out;
            transition-property: left, top;
            &:nth-child(1){
              width: 149px;
              left: 959px;
              top: 413px;
            }
            &:nth-child(2){
              width: 234px;
              left: 68px;
              top: 84px;
            }
            &:nth-child(3){
              width: 180px;
              left: 980px;
              top: 52px;
            }
            &:nth-child(4){
              width: 184px;
              left: 149px;
              top: 461px;
            }
            &:nth-child(5){
              width: 224px;
              left: 680px;
              top: 516px;
            }
            &:nth-child(6){
              width: 174px;
              left: 419px;
              top: 0;
            }
            &:nth-child(7){
              width: 176px;
              left: 355px;
              top: 393px;
            }
            &.active{
              &:nth-child(1){
                left: 1072px;
                top: 516px;
              }
              &:nth-child(2){
                left: 0px;
                top: 516px;
              }
              &:nth-child(3){
                left: 1237px;
                top: 516px;
              }
              &:nth-child(4){
                left: 250px;
                top: 516px;
              }
              &:nth-child(5){
                left: 832px;
                top: 516px;
              }
              &:nth-child(6){
                left: 642px;
                top: 516px;
              }
              &:nth-child(7){
                left: 450px;
                top: 516px;
              }
            }
          }
        }
      }
    }
  }


  .modal{
    position: fixed;
    display: none;
    background: rgba(0,0,0,.5);
    height: 100%;
    left: 0;
    overflow-y: scroll;
    top: 0;
    width: 100%;
    z-index: 1000;
    @media screen and (max-width: 800px) {

    }

    &.active{
      display: block;
    }
    .flex-modal{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 100vh;
      width: 100vw;


      @media screen and (max-width: 800px) {
        width: calc(100vw - 32px);
        left: 16px;
      }
    }

    .empty-modal{
        position: relative;
        display: block;
        width: 100%;
        height: 50px;
    }
    .modal-form{
      position: relative;
      display: block;
      width: 100%;
      max-width: 550px;
      background: #FFFFFF;
      border-radius: 16px;
      min-height: 200px;
      padding: 48px;
      box-sizing: border-box;
      @media screen and (max-width: 800px) {
        padding: 56px 16px;
      }


      small{
        position: relative;
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #5B6076;
        margin-bottom: 32px;
        a{
          text-decoration: none;
          color: #5B6076;
        }
      }

      .close-modal{
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
      }

      .login-to-console{
        position: relative;
        display: block;
        font-weight: 600;
        font-size: 18px;
        line-height: 64px;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #0F192E;
        width: 100%;
        height: 64px;
        background: #EEF0F1;
        border-radius: 16px;
        text-decoration: none;
        @media screen and (max-width: 800px) {
          font-size: 16px;
          line-height: 56px;
          height: 56px;
        }
        &:hover{
          background: #ccc;
        }
      }

      .modal-title{
        position: relative;
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: -0.04em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 24px;
      }
      .modal-subtitle{
        position: relative;
        display: block;
        width: 100%;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #5B6076;
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        margin-top: -16px;
      }

      input{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 12px;
        padding: 0 16px;
        height: 64px;
        border: 1px solid #EBEBEB;
        border-radius: 16px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #0F192E;
        background: none;
        outline: none;
        font-family: 'TT Wellingtons';
        @media screen and (max-width: 800px) {
          height: 56px;
        }
        &::-webkit-input-placeholder{
          color: #8F93A3;
        }
        &:hover{
          border: 1.5px solid #B3B8BF;
        }
        &:focus{
          border: 1px solid #5B6076;
        }
      }

      textarea{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 16px;
        padding: 16px 16px;
        height: 94px;
        border-radius: 16px;
        border: 1.5px solid #eeeff1;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #5b6176;
        background: #fff;
        outline: none;
        resize: none;
        font-family: 'TT Wellingtons';
        @media screen and (max-width: 800px) {
          height: 94px;
        }
        &::-webkit-textarea-placeholder{
          color: #5b6176;
        }
        &:focus{
          border: 1.5px solid rgba(255, 255, 255, 0.92);
        }
      }

      .input-block{
        position: relative;
        display: block;
        &.select-input-block{
          width: 100%;
          margin-bottom: 12px;
          padding: 0 16px;
          height: 64px;
          border: 1px solid #EBEBEB;
          border-radius: 16px;
          box-sizing: border-box;

          .select-title{
            width: 100%;
            height: 62px;
            line-height: 62px;
            color: #5B6076;
            opacity: 0.8;
            cursor: pointer;
            &:after{
              position: absolute;
              display: block;
              width: 24px;
              height: 24px;
              right: 20px;
              top: 20px;
              content: "";
              background: url(../img/blog_select.svg) no-repeat 50% 50%;
            }
          }

          &:hover{
            border: 1px solid #B3B8BF;
          }
          &.active{
            border: 1px solid #5B6076;
            .select-items{
              position: absolute;
              left: 0;
              z-index: 10;
              top: 58px;
              width: 100%;
              box-sizing: border-box;
              display: flex;
              gap: 8px;
              flex-direction: column;
              padding: 24px;
              background: #FFFFFF;
              box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.12);
              border-radius: 16px;
              .select-item{
                position: relative;
                display: block;
                line-height: 24px;
                font-weight: 500;
                font-size: 16px;
                cursor: pointer;
                padding-left: 28px;
                &:before{
                  position: absolute;
                  display: block;
                  content: "";
                  left: 0;
                  top: 2px;
                  width: 16px;
                  height: 16px;
                  background: #F5F6F7;
                  border: 1.5px solid #EBEBEB;
                  border-radius: 4px;
                  box-sizing: border-box;
                }
                &.active{
                  &:before {
                    background: url(../img/select_check.svg) no-repeat 50% 50%;
                    border: 0;
                  }
                }
                &:hover{
                  color: #0F192E;
                }
              }
            }
          }

          .select-items{
            display: none;
          }
        }
        small{
          position: relative;
          text-align: left;
          display: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          margin-top: 8px;
          margin-bottom: 12px;
          color: #FF735D;
        }
        &.error{
          small{
            display: block;
          }
          input{
            background: #FFF6F5;
            border: 1px solid #FF735D;
          }
        }
      }


      button{
        box-sizing: border-box;
        outline: none;
        border: 0;
        width: 100%;
        height: 64px;
        background: linear-gradient(92.45deg, #FF6600 0%, #FF9400 100%);
        backdrop-filter: blur(95px);
        -webkit-backdrop-filter: blur(95px);
        border-radius: 16px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        font-family: 'TT Wellingtons';

        @media screen and (max-width: 800px) {
          font-size: 16px;
          line-height: 56px;
          height: 56px;
        }
      }

    }

    &#success-modal{
      .modal-form{
        text-align: center;
        img{
          position: relative;
          display: block;
          margin: 0 auto 32px;
        }
        b{
          position: relative;
          display: block;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.04em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .again-button{
          background: #EEF0F1;
          border-radius: 16px;
          width: 454px;
          height: 64px;
          font-size: 18px;
          line-height: 140%;
          cursor: pointer;
          text-align: center;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0F192E;
        }
      }
    }

    &#success-job-modal{
      .modal-form{
        text-align: center;
        img{
          position: relative;
          display: block;
          margin: 0 auto 32px;
        }
        b{
          position: relative;
          display: block;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.04em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .again-button{
          background: #EEF0F1;
          border-radius: 16px;
          width: 454px;
          height: 64px;
          font-size: 18px;
          line-height: 140%;
          cursor: pointer;
          text-align: center;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0F192E;
        }
      }
    }

    &#error-modal{
      .modal-form{
        text-align: center;
        img{
          position: relative;
          display: block;
          margin: 0 auto 32px;
        }
        b{
          position: relative;
          display: block;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.04em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .again-button{
          background: #EEF0F1;
          border-radius: 16px;
          width: 454px;
          height: 64px;
          line-height: 64px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0F192E;
          margin-top: 32px;
          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 16px;
            line-height: 54px;
            height: 54px;
          }
        }
      }
    }

    &#calculator-modal{
      .modal-form{
        width: 550px;
        text-align: center;
        @media screen and (max-width: 800px) {
          width: auto;
        }
        span{
          text-align: center;
          letter-spacing: -0.04em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          @media screen and (max-width: 800px) {
            br{
              display: inline-block;
              content: '&nbsp;';
            }
          }
        }
        b{
          position: relative;
          display: block;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          text-align: center;
          letter-spacing: -0.04em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(95.33deg, #121630 0%, #596077 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .yes-button{
          position: relative;
          display: block;
          background: linear-gradient(92.45deg,#f60,#ff9400);
          border-radius: 16px;
          width: 454px;
          height: 64px;
          line-height: 64px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #fff;
          margin-top: 32px;
          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 16px;
            line-height: 54px;
            height: 54px;
          }
        }
        .cancel-button{
          background: #EEF0F1;
          border-radius: 16px;
          width: 454px;
          height: 64px;
          line-height: 64px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
          letter-spacing: -0.02em;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #0F192E;
          margin-top: 12px;
          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 16px;
            line-height: 54px;
            height: 54px;
          }
        }
      }
    }
  }
}


@keyframes progressSlider {
  to{
    width: 100%;
  }
}
[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
}
